import "./polyfills";
// import "./assets/cnsb.css";
import "./index.css";
import App from "./page/App";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./assets/store";
import AccessError from "./page/AccessError";
import constants from "../src/helpers/oidcConstants";
import { AuthProvider } from "react-oidc-context";
import ThemeSelector from "./themes/themeSelector";

const oidcConfig = {
  authority: constants.stsAuthority,
  client_id: constants.clientId,
  redirect_uri: constants.clientRoot + "signin-callback.html",
};
if (
  window.location.hash &&
  window.location.hash !== "#/" &&
  ["#/devices", "#/manage-tdi", "#/manage-passkey"].includes(
    window.location.hash
  )
) {
  sessionStorage.setItem("urlHash", JSON.stringify(window.location.hash))
}
const access_denied = sessionStorage.getItem("access_denied");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeSelector>
      <Provider store={store}>
        <Suspense fallback={""}>
          {access_denied ? (
            <AccessError />
          ) : (
            <AuthProvider {...oidcConfig}>
              <App />
            </AuthProvider>
          )}
        </Suspense>
      </Provider>
    </ThemeSelector>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
