import config from './config';


export const clientErrors = {
  Required_Field: `Required field.`,
  Email_InvalidEmailFormat: `Email must be in the format of 'user@example.com'.`,
  Password_TelstraDomainEmail:`Your new username must be with another email provider`,
  Email_TelstraDomainEmail: `You may have already used this email with us before!`,
  Email_Exist: `Email Entered is already Exist`,
  Mobile_InvalidStart: `Mobile number should starts with +614, 614 or 04`,
  Mobile_InvalidStartWithPlus614: `Mobile number starts with +614 and must be 11-digits long`,
  Mobile_InvalidStartWith614: `Mobile number starts with 614 and must be 11-digits long`,
  Mobile_InvalidStartWith04: `Mobile number starts with 04 and must be 10-digits long`,
  Mobile_Exist: `Mobile Number Entered is already Exist`,
  Pin_Invalid: `Invalid PIN. Your PIN must meet the requirements listed.`,
  Pin_Mismatch: `PINs don't match. Please try again.`,
  Otp_InvalidOtp: `Invalid code. Please check and try again.`,
  Email_AlertDomain: `Is this email address correct? Please confirm before proceeding.`,
  Email_BlockListDomain: `This email address ([$emailVal]) can not be used, please enter another email address.`,
  Email_TopLevelDomain: `This email address ([$emailVal]) can not be used, please enter another email address.`,
};

export const otherMsgs = {
  verifyOtpInvalid: `Sorry, you’ve entered an invalid code, you have &&NoOfAttempts&& attempt(s) remaining. Please check the code and try again.`,
  verifyOtpExpired: `The oneTimePin has expired.`,
  alertDomain: `Is this email address correct? Please confirm before proceeding.`,
  blackListDomain: `This domain is black listed. This email address can not be used`,
  topLevelDomain: `This domain is top level domain. This email address can not be used`,
}

const defaultErrors = {
  err_default: {
    code: "default_error",
    heading: `Something went wrong`,
    msg: `Please try again.`,
    livechat: false
  },
  err_422_3000: {
    code: "err_422_3000",
    heading: `You need to link your account and your Telstra ID to continue.`,
    msg: `<a class='t-able-low-emph-button' href='${config.addAccountUrl}'>Link your account</a>`,
    livechat: false
  },
  err_403_675: {
    code: "err_403_675",
    heading: `Need to stepup to perform action`,
    msg: `you need to stepup to update security settings. Please <a name='signOut' href='${config.logoutUrl}'>Sign in again</a>.`,
    livechat: false
  },
  err_422_3100: {
    code: "err_422_3100",
    heading: `Something went wrong`,
    msg: `Unlinked user. <a target='_blank' href='${config.addAccountUrl}'>Add Account</a>.`,
    livechat: false
  },
  passcode_err_400_614: {
    code: "passcode_err_400_614",
    heading: `Something went wrong`,
    msg: `User already registered for passcode. Passcode cannot be created.`,
    livechat: false
  },
  passcode_err_400_675: {
    code: "passcode_err_400_675",
    heading: `Need to stepup to perform action`,
    msg: `you need to stepup to update security settings. Please <a name='signOut' href='${config.logoutUrl}'>Sign in again</a>.`,
    livechat: false
  },
  passcode_err_400_925: {
    code: "passcode_err_400_925",
    heading: `Something went wrong`,
    msg: `User Is Blocked. Passcode cannot be created`,
    livechat: false
  },
  err_400_604: {
    code: "err_400_604",
    heading: `Something went wrong`,
    msg: `No data found`,
    livechat: false
  },
  err_403_603: {
    code: "err_422_3100",
    heading: `Something went wrong`,
    msg: `Forbidden`,
    livechat: false
  },
  err_404_606: {
    code: "err_422_3100",
    heading: `Something went wrong`,
    msg: `No results found`,
    livechat: false
  },
};

export const serverErrors = defaultErrors;