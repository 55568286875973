const routeInfo = {
  landing: {
    routename: "/",
    componentName: 'Landing',
    title: "Advanced security settings"
  },
  ManageTDI: {
    routename: "/manage-tdi",
    componentName: 'Manage Telstra ID',
    title: "Manage your Telstra IDs"
  },
  ManagePassKey: {
    routename: "/manage-passkey",
    componentName: 'Manage Passkeys',
    title: "Manage your Passkey"
  },
  ChangeUserName: {
    routename: "/manage-tdi/change-username",
    componentName: 'Change Username',
    title: "Your new Telstra ID"
  },
  ChangeUserLinkSent: {
    routename: "/manage-tdi/change-username/link-sent",
    componentName: 'Verification link sent',
    title: "Verification link sent"
  },
  TIDError: {
    routename: "/manage-tdi/error",
    componentName: 'Manage Telstra ID Error',
    title: "Something went wrong!"
  },
  // twoSV: {
  //   routename: "/settings",
  //   componentName: 'Settings-Landing',
  //   title: "Two-step verification"
  // },
  // setPin: {
  //   routename: "/settings/setup-pin",
  //   componentName: 'SetupPin',
  //   title: "Set up your Telstra PIN"
  // },
  // createPin: {
  //   routename: "/settings/create-pin",
  //   componentName: 'CreatePin',
  //   title: "Set up your Telstra PIN"
  // },
  // pinSuccess: {
  //   routename: "/settings/pin-success",
  //   componentName: 'PinSuccess',
  //   title: {
  //     "newPin" : "Your Telstra PIN is set up",
  //     "pinAlreadySet": "Your Telstra PIN is already set up"
  //   }
  // },
  // turnOff2SV: {
  //   routename: "/settings/optout",
  //   componentName: 'TurnOffPin',
  //   title: "Are you sure?"
  // },
  // contacts: {
  //   routename: "/settings/contacts",
  //   componentName: 'Contacts',
  //   title: {
  //     "checkContacts" : "Check your contact details",
  //     "verifyContacts": "Verify your contact details"
  //   }
  // },
  // updateContacts: {
  //   routename: "/settings/contact-update",
  //   componentName: 'ContactUpdate',
  //   title: {
  //     "updateEmail" : "Update your email address",
  //     "addEmail": "Add a email address",
  //     "updateMobile": "Update your mobile number",
  //     "addMobile": "Add a mobile number"
  //   }
  // },
  // verifyOtp: {
  //   path: '/settings/verify',
  //   component: 'Verify',
  //   title: {
  //     "verifyEmail" : "Verify access to this email address",
  //     "verifyMobile": "Verify access to this number"
  //   }
  // },
  // splash: {
  //   path: '/settings/splash',
  //   component: 'VerifySuccess',
  //   title: {
  //     "emailVerified" : "Email address verified",
  //     "mobileVerified": "Mobile number verified"
  //   }
  // },
  devices: {
    path: '/devices',
    component: 'Devices',
    title: "Device management"
  },
  lockOut: {
    path: '/lockout',
    component: 'Lockout',
    title: "Your account has been temporarily locked"
  },
  error: {
    routename: "/error",
    componentName: 'Error',
    title: "Something went wrong"
  },
  accessDenied: {
    routename: "/access-denied",
    componentName: 'AccessError',
    title: "You’ll need to verify"
  }
}

export const getRouteData = (route) => {
  var returnObj;
  var isRouteExisted = false;
  Object.keys(routeInfo).forEach(item => {
    if (routeInfo[item].routename == route) {
      returnObj = routeInfo[item];
      isRouteExisted = true;
    }
  });
  if (!isRouteExisted)
    return routeInfo.error;
  else
    return returnObj;
}

export default routeInfo;