// import { call, put, takeLatest, all } from 'redux-saga/effects';
import { all } from "redux-saga/effects";
// import { PostsAPI } from '../API';
import twoSVDetails from "./twoSVDetails";
import manageTdi from "./manageTdi";
import managePassKey from "./managePassKey";

export default function* rootSaga() {
  yield all([twoSVDetails(), manageTdi(), managePassKey()]);
}
