import { Actions } from '../constants/twoSVDetails';

export const initialState = {
  isLoading: false,
  mfaStatus: null,
  mfaError: null,
  contactDetails: {},
  contactError: null,
  isOTPSent: null,
  sentOtpRemainingCount: 4,
  contactMedium: {},
  isOTPVerified: null,
  verifyOtpRemainingCount: 3,
  verifyOtpmsg: "",
  isContactUpdated: null,
  mfaEnabled: null,
  mfaEnabledError: null,
  mfaDisabled: null,
  mfaDisabledError: null,
  pinStatus: {},
  pinStatusError: null,
  pinCreate: null,
  pinCreateError: null,
  commonError: null,
  recentAction: {},
  domainErrorStatus: null,
  domainErrorMsg: "",
  cipUid: null,
  trustedDevices: [],
  trustedDevicesError: null,
  revokeSuccess: null,
  revokedDevices: []
}

const devicesAfterRevoke = (devices, allTrustedDevices) => {
  let remainingDevices = allTrustedDevices;
  devices.map((device) =>{
    remainingDevices.map((remDev,index)=>{
      if(remDev.fingerprintId === device){
        remainingDevices.splice(index, 1);
      }
    })
  });
  return remainingDevices;
}

export const twoSVDetails = (state = initialState, action) => {
  switch (action.type) {
    case Actions.fetchMfaStatus: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.fetchMfaStatusSuccess: {
      return {
        ...state,
        isLoading: false,
        mfaStatus: action.payload,
        mfaError: false
      }
    }
    case Actions.fetchMfaStatusError: {
      return {
        ...state,
        isLoading: false,
        mfaStatus: action.payload,
        commonError: action.payload,
        mfaError: true,
      }
    }
    case Actions.fetchContacts: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.fetchContactsSuccess: {
      return {
        ...state,
        isLoading: false,
        contactDetails: action.payload,
        contactError: false
      }
    }
    case Actions.fetchContactsError: {
      return {
        ...state,
        isLoading: false,
        contactError: true,
        commonError: action.payload
      }
    }
    case Actions.sendOTP: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case Actions.sendOTPSuccess: {
      return {
        ...state,
        isLoading: false,
        isOTPSent: action.payload,
        isOTPVerified: false,
        domainErrorStatus: false,
        domainErrorMsg: "",
      }
    }
    case Actions.sendOTPError: {
      if (action.payload.domainErrorStatus) {
        return {
          ...state,
          isLoading: false,
          domainErrorStatus: action.payload.domainErrorStatus,
          domainErrorMsg: action.payload.domainErrorMsg,
          isOTPSent: action.payload,
          commonError: action.payload
        }
      } else {
        return {
          ...state,
          isLoading: false,
          domainErrorStatus: false,
          domainErrorMsg: "",
          isOTPSent: action.payload,
          commonError: action.payload
        }
      }
    }
    case Actions.saveContactMedium: {
      return {
        ...state,
        contactMedium: action.payload
      }
    }
    case Actions.verifyOTP: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case Actions.verifyOTPSuccess: {
      return {
        ...state,
        isLoading: false,
        isOTPVerified: action.payload
      }
    }
    case Actions.verifyOTPError: {
      if (action.payload.remainingCount === null) {
        return {
          ...state,
          isLoading: false,
          isOTPVerified: action.payload.verifyStatus,
          verifyOtpmsg: action.payload.verifyOtpmsg,
          commonError: action.payload
        }
      } else {
        return {
          ...state,
          isLoading: false,
          isOTPVerified: action.payload.verifyStatus,
          verifyOtpRemainingCount: action.payload.remainingCount,
          verifyOtpmsg: action.payload.verifyOtpmsg,
          commonError: action.payload
        }
      }
    }
    case Actions.updateContacts: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case Actions.updateContactsSuccess: {
      return {
        ...state,
        isLoading: false,
        isContactUpdated: action.payload,
        domainErrorStatus: false,
        domainErrorMsg: "",
      }
    }
    case Actions.updateContactsError: {
      if (action.payload.domainErrorStatus) {
        return {
          ...state,
          isLoading: false,
          domainErrorStatus: action.payload.domainErrorStatus,
          domainErrorMsg: action.payload.domainErrorMsg,
          isContactUpdated: action.payload,
          commonError: action.payload
        }
      } else {
        return {
          ...state,
          isLoading: false,
          domainErrorStatus: false,
          domainErrorMsg: "",
          isContactUpdated: action.payload,
          commonError: action.payload
        }
      }
    }
    case Actions.fetchPinStatus: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.fetchPinStatusSuccess: {
      return {
        ...state,
        isLoading: false,
        pinStatus: action.payload,
        pinStatusError: false
      }
    }
    case Actions.fetchPinStatusError: {
      return {
        ...state,
        isLoading: false,
        pinStatus: action.payload,
        commonError: action.payload,
        pinStatusError: true
      }
    }
    case Actions.createPin: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.createPinSuccess: {
      return {
        ...state,
        isLoading: false,
        pinCreate: action.payload.status,
        pinStatus: action.payload,
        pinCreateError: false
      }
    }
    case Actions.createPinError: {
      return {
        ...state,
        isLoading: false,
        pinCreate: action.payload.status,
        commonError: action.payload,
        pinCreateError: true
      }
    }
    case Actions.mfaEnable: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.mfaEnableSuccess: {
      return {
        ...state,
        isLoading: false,
        mfaEnabled: action.payload,
        mfaEnabledError: false
      }
    }
    case Actions.mfaEnableError: {
      return {
        ...state,
        isLoading: false,
        mfaEnabled: action.payload,
        commonError: action.payload,
        mfaEnabledError: true
      }
    }
    case Actions.mfaDisable: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.mfaDisableSuccess: {
      return {
        ...state,
        isLoading: false,
        mfaDisabled: action.payload,
        mfaDisabledError: false
      }
    }
    case Actions.mfaDisableError: {
      return {
        ...state,
        isLoading: false,
        mfaDisabled: action.payload,
        commonError: action.payload,
        mfaDisabledError: true
      }
    }
    case Actions.saveActionAndArgs: {
      return {
        ...state,
        recentAction: action.payload
      }
    }
    case Actions.setDomainErrorValues: {
      return {
        ...state,
        domainErrorStatus: action.payload.domainErrorStatus,
        domainErrorMsg: action.payload.domainErrorMsg
      }
    }
    case Actions.fetchTrustedDevices: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.fetchTrustedDevicesSuccess: {
      return {
        ...state,
        isLoading: false,
        cipUid: action.payload.cipuid ? action.payload.cipuid : null,
        trustedDevices: action.payload.trustDeviceDetails,
        revokeSuccess: null,
        revokedDevices: [],
        trustedDevicesError: false
      }
    }
    case Actions.fetchTrustedDevicesError: {
      return {
        ...state,
        isLoading: false,
        commonError: action.payload,
        cipUid: null,
        trustedDevices: [],
        revokeSuccess: null,
        revokedDevices: [],
        trustedDevicesError: true,
      }
    }
    case Actions.revokeTrustedDevices: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.revokeTrustedDevicesSuccess: {
      return {
        ...state,
        isLoading: false,
        revokeSuccess: action.payload.revoked,
        revokedDevices: action.payload.revokedDevices,
        trustedDevices: devicesAfterRevoke(action.payload.revokedDevices, state.trustedDevices)
      }
    }
    case Actions.revokeTrustedDevicesError: {
      return {
        ...state,
        isLoading: false,
        commonError: action.payload,
      }
    }
    default:
      return state
  }
}