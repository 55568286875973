import React from "react";
import { useEffect, useState } from "react";

import RootRoute from "./route";
import Loader from "../page/Loader";
import { useAuth } from "react-oidc-context";
import { User } from "oidc-client-ts";
import { ThemeConetent } from "../shared/commonFunctions";

function App() {
  const auth = useAuth();

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const prefix =
      ThemeConetent.appTheme === "cnsb"
        ? "default-"
        : `${ThemeConetent.appTheme}-`;
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = `./${prefix}favicon.ico`;
    }
  }, []);

  let baseUrl = window.location.origin;
  if (window.location.hostname === "localhost") {
    baseUrl = "https://fn1.myid-nonprod.telstra.com";
  }
  if (
    window.location.hash &&
    window.location.hash !== "#/" &&
    ["#/devices", "#/manage-tdi", "#/manage-passkey"].includes(
      window.location.hash
    )
  ) {
    sessionStorage.setItem("urlHash", JSON.stringify(window.location.hash))
  }

  function getUser() {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${baseUrl}/identity:b2c-identityportal`
    );
    if (!oidcStorage) {
      auth.signinRedirect();
      return null;
    } else {
      setLoader(false);
      sessionStorage.setItem("AuthData", oidcStorage);
    }

    return User.fromStorageString(oidcStorage);
  }

  useEffect(() => {
    getUser();
  }, []);

  return <React.Fragment>{loader ? <Loader /> : <RootRoute />}</React.Fragment>;
}

export default App;
