const ClientStyles = [
  {
    appTheme: "cnsb",
    landingScreenContent: {
      MainHeading: "Advanced security settings",
      ActionListItemOneLabel: "Manage your Telstra IDs",
      ActionListItemOneSubText:
        "View and update your Telstra IDs and passwords.",
      ActionListItemTwoLabel: "Device management",
      ActionListItemTwoSubText: "Manage trusted devices and browsers.",
      ActionListItemThreeLabel: "Sign up for passkey",
      ActionListItemThreeSubText:
        "Enjoy a more secure, fast and simple way to sign in to your Telstra account.",

      BackButtonLabel: "Close",
    },
    ManageTDIContent: {
      MainHeading: "Manage your Telstra IDs",
      DelinkSuccess: "Telstra ID removed for ",
      ContentOne:
        " You have one Telstra ID. Consider increasing your password complexity to improve your account security.",
      ContentTwo:
        "Your Telstra IDs can make changes to your account. To improve your account security, delete the Telstra IDs no longer in use.",
      ContentThree:
        " Removing your Telstra IDs will not cancel your services or your Telstra BigPond mailbox.",
      ContentFour: "Your Telstra IDs",
      ModalContent: "We’ll remove access to your Telstra ID for ",
    },
    ManagePassKeyContent: {
      MainHeading: "Passkeys",
      SubHeading:
        "Passwords are often easy to guess and hack. Passkey provides a more secure, fast and simple way to sign in to your Telstra account. No two-step verification needed.",
      ContentOne: "Passkey status",
      ContentTwo: "Active",
      ContentThree: "Telstra ID",
      ContentFour: "Passkeys you have created",
      CreateButtonLabel: "Create a passkey",
      BackButtonLabel: "Back",
      CreatePassKeyLabel: "How to get started",
      CreatePasskeyLearnMore: "Learn more about passkeys",
      CreatePassKeyContentOne: `Click <span class="bold-font">Create a passkey</span> to get started`,
      CreatePassKeyContentTwo: "Only create a passkey on devices you own",
      CreatePassKeyContentThree:
        "If its your first time, you might be asked to allow passkeys, or to enable your device screen lock",
      CreatePasskeyInfo:
        "You can create additional passkeys on another device, or on a FIDO2 hardware security key. Follow your device prompts.",
      ContentSix: "FAQs",
      CreatePasskeyTop: "Top",
      FaqDetails: [
        {
          title: "What's a passkey?",
          content: `<ul>
        <li>Passkeys are a secure alternative to passwords for logging into websites. It is expected that they will eventually replace passwords everywhere.    Each website has its own unique passkey stored on your device, protected by your fingerprint, face, or device PIN/password.</li>
        <li>Passkeys sync across devices using Apple iCloud or Google account and work on various platforms. The FIDO Alliance, supported by major organisations including the Australian Federal Government, leads the open standard for passkeys.</li>
       </ul>`,
        },
        {
          title:
            "How does a passkey work, and how is it more secure than a password?",
          content: `<ul>
        <li>A passkey consists of a private key stored on your device and a public key shared with the website. To sign in, unlock your device with fingerprint, face, or PIN. The device and website verify the connection between the private and public keys.</li>
        <li>Passkeys are strong, phishing-resistant, and require physical possession of your device, protecting against hacking.</li>
        </ul>`,
        },
        {
          title: "What are the benefits of a passkey?",
          content: `<ul>
        <li>Passkeys are a secure and convenient alternative to passwords and two-step verification. Your device serves as the main authentication factor, safeguarded by fingerprint, face, or PIN/password.</li>
        <li>Passkeys prevent impersonation and protect against foreign crime organisations. They also prevent data breaches by never sharing your private key with other websites. Passkeys eliminate the need for memorisation, are always unique, and offer robust account protection.</li>
        </ul>`,
        },
        {
          title: "How do I set up a passkey for my account?",
          content: `<ul>
        <li>You’ll be prompted to create a passkey on compatible devices when you sign in. If you’ve already signed in, you can add a passkey to your account by going to Advanced Security Settings -> Passkeys and follow the prompts.</li>
        </ul>`,
        },
        {
          title: "What do I need to create and use a passkey?",
          content: `<ul>
        <li>Passkeys can be created on most smartphones, laptops and desktop computers.</li>
        <li>You must have a screen lock, fingerprint or face biometric enabled on your device, and have signed in and enabled your Apple iCloud or Google Password Manager.</li>
        </ul>`,
        },
        {
          title: "What devices support passkey?",
          content: `<ul>
        <li>Apple devices running iOS16+.</li>
        <li>Apple MacOS devices running macOS 13+.</li>
        <li>Windows devices running Windows 11.</li>
        <li>Android devices running Android 9+.</li>
        <li>Hardware security keys that support FIDO2 and user verification.</li>
        </ul>`,
        },
        {
          title: "Does Telstra get access to my biometrics or my device PIN?",
          content: `<ul>
        <li>No. Your biometrics and device PIN are stored locally on your device. When using biometrics to unlock your passkey, your device verifies that it belongs to you. Once authenticated, the passkey is confirmed with Telstra to ensure the correct user has access.</li>
        </ul>`,
        },
        {
          title: "How do I sign on to a different device?",
          content: `<ul>
        <li>To sign in on a different device, use the passkey stored on your smartphone. After entering your username, scan the QR code with your phone camera. If you trust the device, create a passkey for easier future sign-ins. Alternatively, you can use your username, password, and two-step verification to sign in.</li>
        </ul>`,
        },
        {
          title: "What happens if I lose my mobile phone or computer?",
          content: `<ul>
        <li>Even if you lose your device, your account remains secure. Criminals would need to unlock your device with your PIN/password to sign in.</li>
        <li>You can delete the passkey from the lost device through Advanced Security settings in My Telstra. Consider remote-device wipe if available.</li>
        <li>If you can't log in, complete an account recovery process by providing your name, date of birth, and completing two-step verification.</li>
        </ul>`,
        },
        {
          title: "Can I remotely remove it from my device if I lose my device?",
          content: `<ul>
        <li>Yes, you can remove passkeys from the Advanced Security Settings. If your device is lost or stolen, you should also set your device to lost mode or trigger a remote wipe so that your personal information remains secure.</li>
        </ul>`,
        },
        {
          title:
            "If I change devices, how would I de-activate the passkey from my original device before adding my new one?",
          content: `<ul>
        <li>Your passkey will automatically sync to your new device when signing in to your iCloud or Google account. Remember to sign out and delete the passkey from your old device's passkey management screens. Consider erasing all data from the device, following instructions from your device manufacturer's support pages.</li>
        </ul>`,
        },
        {
          title:
            "Can I change my passkey if I suspect it has been compromised?",
          content: `<ul>
        <li>Yes, if you suspect that your passkey has been compromised, you should change it immediately. Go to ‘Passkeys’ from Advanced Security Settings and delete your passkey.</li>
        </ul>`,
        },
        {
          title: "Do all digital tools and platforms support passkeys?",
          content: `<ul>
        <li>Not every site uses passkeys today, but passkeys are now supported by most major websites, including Google, Gmail, PayPal, Ebay, Amazon, TikTok and Twitter. Passkeys are becoming more common as a secure and convenient form of authentication.</li>
        </ul>`,
        },
        {
          title:
            "Are there any additional security measures I should consider alongside using passkeys?",
          content: `<ul>
        <li>Yes, it’s important to use a secure PIN or screen lock, keep your device updated with the latest software, and only sign in to websites on devices you personally own.</li>
        </ul>`,
        },
        {
          title:
            "Can I give my partner/sister/children/dog a passkey to my account?",
          content: `<ul>
        <li>Telstra doesn’t recommend you share your passkey with anyone. However, you can share a passkey with someone you trust, and who you are in close proximity with, through your device sharing menu.</li>
        </ul>`,
        },
      ],
    },
    ChangeUserNameContent: {
      MainHeading: "Your new Telstra ID",
      ContentOne:
        "Provide the email address you would like to use as your Telstra ID.",
    },
    headerContext: {
      imgSource: "./Telstralogo.svg",
      altText: "telstra logo",
    },
  },
  {
    appTheme: "boost",
    headerContext: {
      imgSource: "./boost-logo-wl.svg",
      altText: "boost logo",
    },
    landingScreenContent: {
      MainHeading: "Advanced security settings",
      ActionListItemOneLabel: "Manage your username's",
      ActionListItemOneSubText:
        "View and update your username's and passwords.",
      ActionListItemTwoLabel: "Device management",
      ActionListItemTwoSubText: "Manage trusted devices and browsers.",
      ActionListItemThreeLabel: "Sign up for passkey",
      ActionListItemThreeSubText:
        "Enjoy a more secure, fast and simple way to sign in to your Telstra account.",
      BackButtonLabel: "Close",
    },
    ManageTDIContent: {
      MainHeading: "Manage your username's",
      DelinkSuccess: "Username removed for ",
      ContentOne:
        " You have one username. Consider increasing your password complexity to improve your account security.",
      ContentTwo:
        "Your username's can make changes to your account. To improve your account security, delete the username's no longer in use.",
      ContentThree: " Removing your username's will not cancel your services.",
      ContentFour: "Your username's",
      ModalContent: "We’ll remove access to your username for ",
    },
    ManagePassKeyContent: {
      MainHeading: "Passkeys",
      SubHeading:
        "Passwords are often easy to guess and hack. Passkey provides a more secure, fast and simple way to sign in to your Telstra account. No two-step verification needed.",
      ContentOne: "Passkey status",
      ContentTwo: "Active",
      ContentThree: "Username",
      ContentFour: "Passkeys you have created",
      ContentFive:
        "You can not register another device if you’re using a mobile or tablet device.",
      CreateButtonLabel: "Create a passkey",
      BackButtonLabel: "Back",
      CreatePassKeyLabel: "How to get started",
      CreatePasskeyLearnMore: "Learn more about passkeys",
      CreatePassKeyContentOne: `Click <span class="bold-font">Create a passkey</span> to get started`,
      CreatePassKeyContentTwo: "Only create a passkey on devices you own",
      CreatePassKeyContentThree:
        "If its your first time, you might be asked to allow passkeys, or to enable your device screen lock",
      CreatePasskeyInfo:
        "You can create additional passkeys on another device, or on a FIDO2 hardware security key. Follow your device prompts.",
      ContentSix: "FAQs",
      CreatePasskeyTop: "Top",
      FaqDetails: [
        {
          title: "What's a passkey?",
          content: `<ul>
        <li>Passkeys are a secure alternative to passwords for logging into websites. It is expected that they will eventually replace passwords everywhere.    Each website has its own unique passkey stored on your device, protected by your fingerprint, face, or device PIN/password.</li>
        <li>Passkeys sync across devices using Apple iCloud or Google account and work on various platforms. The FIDO Alliance, supported by major organisations including the Australian Federal Government, leads the open standard for passkeys.</li>
       </ul>`,
        },
        {
          title:
            "How does a passkey work, and how is it more secure than a password?",
          content: `<ul>
        <li>A passkey consists of a private key stored on your device and a public key shared with the website. To sign in, unlock your device with fingerprint, face, or PIN. The device and website verify the connection between the private and public keys.</li>
        <li>Passkeys are strong, phishing-resistant, and require physical possession of your device, protecting against hacking.</li>
        </ul>`,
        },
        {
          title: "What are the benefits of a passkey?",
          content: `<ul>
        <li>Passkeys are a secure and convenient alternative to passwords and two-step verification. Your device serves as the main authentication factor, safeguarded by fingerprint, face, or PIN/password.</li>
        <li>Passkeys prevent impersonation and protect against foreign crime organisations. They also prevent data breaches by never sharing your private key with other websites. Passkeys eliminate the need for memorisation, are always unique, and offer robust account protection.</li>
        </ul>`,
        },
        {
          title: "How do I set up a passkey for my account?",
          content: `<ul>
        <li>You’ll be prompted to create a passkey on compatible devices when you sign in. If you’ve already signed in, you can add a passkey to your account by going to Advanced Security Settings -> Passkeys and follow the prompts.</li>
        </ul>`,
        },
        {
          title: "What do I need to create and use a passkey?",
          content: `<ul>
        <li>Passkeys can be created on most smartphones, laptops and desktop computers.</li>
        <li>You must have a screen lock, fingerprint or face biometric enabled on your device, and have signed in and enabled your Apple iCloud or Google Password Manager.</li>
        </ul>`,
        },
        {
          title: "What devices support passkey?",
          content: `<ul>
        <li>Apple devices running iOS16+.</li>
        <li>Apple MacOS devices running macOS 13+.</li>
        <li>Windows devices running Windows 11.</li>
        <li>Android devices running Android 9+.</li>
        <li>Hardware security keys that support FIDO2 and user verification.</li>
        </ul>`,
        },
        {
          title: "Does Telstra get access to my biometrics or my device PIN?",
          content: `<ul>
        <li>No. Your biometrics and device PIN are stored locally on your device. When using biometrics to unlock your passkey, your device verifies that it belongs to you. Once authenticated, the passkey is confirmed with Telstra to ensure the correct user has access.</li>
        </ul>`,
        },
        {
          title: "How do I sign on to a different device?",
          content: `<ul>
        <li>To sign in on a different device, use the passkey stored on your smartphone. After entering your username, scan the QR code with your phone camera. If you trust the device, create a passkey for easier future sign-ins. Alternatively, you can use your username, password, and two-step verification to sign in.</li>
        </ul>`,
        },
        {
          title: "What happens if I lose my mobile phone or computer?",
          content: `<ul>
        <li>Even if you lose your device, your account remains secure. Criminals would need to unlock your device with your PIN/password to sign in.</li>
        <li>You can delete the passkey from the lost device through Advanced Security settings in My Telstra. Consider remote-device wipe if available.</li>
        <li>If you can't log in, complete an account recovery process by providing your name, date of birth, and completing two-step verification.</li>
        </ul>`,
        },
        {
          title: "Can I remotely remove it from my device if I lose my device?",
          content: `<ul>
        <li>Yes, you can remove passkeys from the Advanced Security Settings. If your device is lost or stolen, you should also set your device to lost mode or trigger a remote wipe so that your personal information remains secure.</li>
        </ul>`,
        },
        {
          title:
            "If I change devices, how would I de-activate the passkey from my original device before adding my new one?",
          content: `<ul>
        <li>Your passkey will automatically sync to your new device when signing in to your iCloud or Google account. Remember to sign out and delete the passkey from your old device's passkey management screens. Consider erasing all data from the device, following instructions from your device manufacturer's support pages.</li>
        </ul>`,
        },
        {
          title:
            "Can I change my passkey if I suspect it has been compromised?",
          content: `<ul>
        <li>Yes, if you suspect that your passkey has been compromised, you should change it immediately. Go to ‘Passkeys’ from Advanced Security Settings and delete your passkey.</li>
        </ul>`,
        },
        {
          title: "Do all digital tools and platforms support passkeys?",
          content: `<ul>
        <li>Not every site uses passkeys today, but passkeys are now supported by most major websites, including Google, Gmail, PayPal, Ebay, Amazon, TikTok and Twitter. Passkeys are becoming more common as a secure and convenient form of authentication.</li>
        </ul>`,
        },
        {
          title:
            "Are there any additional security measures I should consider alongside using passkeys?",
          content: `<ul>
        <li>Yes, it’s important to use a secure PIN or screen lock, keep your device updated with the latest software, and only sign in to websites on devices you personally own.</li>
        </ul>`,
        },
        {
          title:
            "Can I give my partner/sister/children/dog a passkey to my account?",
          content: `<ul>
        <li>Telstra doesn’t recommend you share your passkey with anyone. However, you can share a passkey with someone you trust, and who you are in close proximity with, through your device sharing menu.</li>
        </ul>`,
        },
      ],
    },
    ChangeUserNameContent: {
      MainHeading: "Your new username",
      ContentOne:
        "Provide the email address you would like to use as your username.",
    },
  },
  {
    appTheme: "jbhifi",
    landingScreenContent: {
      MainHeading: "Advanced security settings",
      ActionListItemOneLabel: "Manage your username's",
      ActionListItemOneSubText:
        "View and update your username's and passwords.",
      ActionListItemTwoLabel: "Device management",
      ActionListItemTwoSubText: "Manage trusted devices and browsers.",
      ActionListItemThreeLabel: "Sign up for passkey",
      ActionListItemThreeSubText:
        "Enjoy a more secure, fast and simple way to sign in to your Telstra account.",
      BackButtonLabel: "Close",
    },
    ManageTDIContent: {
      MainHeading: "Manage your username's",
      DelinkSuccess: "Username removed for ",
      ContentOne:
        " You have one username. Consider increasing your password complexity to improve your account security.",
      ContentTwo:
        "Your username's can make changes to your account. To improve your account security, delete the username's no longer in use.",
      ContentThree: " Removing your username's will not cancel your services.",
      ContentFour: "Your username's",
      ModalContent: "We’ll remove access to your username for ",
    },
    ManagePassKeyContent: {
      MainHeading: "Passkeys",
      SubHeading:
        "Passwords are often easy to guess and hack. Passkey provides a more secure, fast and simple way to sign in to your Telstra account. No two-step verification needed.",
      ContentOne: "Passkey status",
      ContentTwo: "Active",
      ContentThree: "Username",
      ContentFour: "Passkeys you have created",
      ContentFive:
        "You can not register another device if you’re using a mobile or tablet device.",
      CreateButtonLabel: "Create a passkey",
      BackButtonLabel: "Back",
      CreatePassKeyLabel: "How to get started",
      CreatePasskeyLearnMore: "Learn more about passkeys",
      CreatePassKeyContentOne: `Click <span class="bold-font">Create a passkey</span> to get started`,
      CreatePassKeyContentTwo: "Only create a passkey on devices you own",
      CreatePassKeyContentThree:
        "If its your first time, you might be asked to allow passkeys, or to enable your device screen lock",
      CreatePasskeyInfo:
        "You can create additional passkeys on another device, or on a FIDO2 hardware security key. Follow your device prompts.",
      ContentSix: "FAQs",
      CreatePasskeyTop: "Top",
      FaqDetails: [
        {
          title: "What's a passkey?",
          content: `<ul>
        <li>Passkeys are a secure alternative to passwords for logging into websites. It is expected that they will eventually replace passwords everywhere.    Each website has its own unique passkey stored on your device, protected by your fingerprint, face, or device PIN/password.</li>
        <li>Passkeys sync across devices using Apple iCloud or Google account and work on various platforms. The FIDO Alliance, supported by major organisations including the Australian Federal Government, leads the open standard for passkeys.</li>
       </ul>`,
        },
        {
          title:
            "How does a passkey work, and how is it more secure than a password?",
          content: `<ul>
        <li>A passkey consists of a private key stored on your device and a public key shared with the website. To sign in, unlock your device with fingerprint, face, or PIN. The device and website verify the connection between the private and public keys.</li>
        <li>Passkeys are strong, phishing-resistant, and require physical possession of your device, protecting against hacking.</li>
        </ul>`,
        },
        {
          title: "What are the benefits of a passkey?",
          content: `<ul>
        <li>Passkeys are a secure and convenient alternative to passwords and two-step verification. Your device serves as the main authentication factor, safeguarded by fingerprint, face, or PIN/password.</li>
        <li>Passkeys prevent impersonation and protect against foreign crime organisations. They also prevent data breaches by never sharing your private key with other websites. Passkeys eliminate the need for memorisation, are always unique, and offer robust account protection.</li>
        </ul>`,
        },
        {
          title: "How do I set up a passkey for my account?",
          content: `<ul>
        <li>You’ll be prompted to create a passkey on compatible devices when you sign in. If you’ve already signed in, you can add a passkey to your account by going to Advanced Security Settings -> Passkeys and follow the prompts.</li>
        </ul>`,
        },
        {
          title: "What do I need to create and use a passkey?",
          content: `<ul>
        <li>Passkeys can be created on most smartphones, laptops and desktop computers.</li>
        <li>You must have a screen lock, fingerprint or face biometric enabled on your device, and have signed in and enabled your Apple iCloud or Google Password Manager.</li>
        </ul>`,
        },
        {
          title: "What devices support passkey?",
          content: `<ul>
        <li>Apple devices running iOS16+.</li>
        <li>Apple MacOS devices running macOS 13+.</li>
        <li>Windows devices running Windows 11.</li>
        <li>Android devices running Android 9+.</li>
        <li>Hardware security keys that support FIDO2 and user verification.</li>
        </ul>`,
        },
        {
          title: "Does Telstra get access to my biometrics or my device PIN?",
          content: `<ul>
        <li>No. Your biometrics and device PIN are stored locally on your device. When using biometrics to unlock your passkey, your device verifies that it belongs to you. Once authenticated, the passkey is confirmed with Telstra to ensure the correct user has access.</li>
        </ul>`,
        },
        {
          title: "How do I sign on to a different device?",
          content: `<ul>
        <li>To sign in on a different device, use the passkey stored on your smartphone. After entering your username, scan the QR code with your phone camera. If you trust the device, create a passkey for easier future sign-ins. Alternatively, you can use your username, password, and two-step verification to sign in.</li>
        </ul>`,
        },
        {
          title: "What happens if I lose my mobile phone or computer?",
          content: `<ul>
        <li>Even if you lose your device, your account remains secure. Criminals would need to unlock your device with your PIN/password to sign in.</li>
        <li>You can delete the passkey from the lost device through Advanced Security settings in My Telstra. Consider remote-device wipe if available.</li>
        <li>If you can't log in, complete an account recovery process by providing your name, date of birth, and completing two-step verification.</li>
        </ul>`,
        },
        {
          title: "Can I remotely remove it from my device if I lose my device?",
          content: `<ul>
        <li>Yes, you can remove passkeys from the Advanced Security Settings. If your device is lost or stolen, you should also set your device to lost mode or trigger a remote wipe so that your personal information remains secure.</li>
        </ul>`,
        },
        {
          title:
            "If I change devices, how would I de-activate the passkey from my original device before adding my new one?",
          content: `<ul>
        <li>Your passkey will automatically sync to your new device when signing in to your iCloud or Google account. Remember to sign out and delete the passkey from your old device's passkey management screens. Consider erasing all data from the device, following instructions from your device manufacturer's support pages.</li>
        </ul>`,
        },
        {
          title:
            "Can I change my passkey if I suspect it has been compromised?",
          content: `<ul>
        <li>Yes, if you suspect that your passkey has been compromised, you should change it immediately. Go to ‘Passkeys’ from Advanced Security Settings and delete your passkey.</li>
        </ul>`,
        },
        {
          title: "Do all digital tools and platforms support passkeys?",
          content: `<ul>
        <li>Not every site uses passkeys today, but passkeys are now supported by most major websites, including Google, Gmail, PayPal, Ebay, Amazon, TikTok and Twitter. Passkeys are becoming more common as a secure and convenient form of authentication.</li>
        </ul>`,
        },
        {
          title:
            "Are there any additional security measures I should consider alongside using passkeys?",
          content: `<ul>
        <li>Yes, it’s important to use a secure PIN or screen lock, keep your device updated with the latest software, and only sign in to websites on devices you personally own.</li>
        </ul>`,
        },
        {
          title:
            "Can I give my partner/sister/children/dog a passkey to my account?",
          content: `<ul>
        <li>Telstra doesn’t recommend you share your passkey with anyone. However, you can share a passkey with someone you trust, and who you are in close proximity with, through your device sharing menu.</li>
        </ul>`,
        },
      ],
    },
    ChangeUserNameContent: {
      MainHeading: "Your new username",
      ContentOne:
        "Provide the email address you would like to use as your username.",
    },
    headerContext: {
      imgSource: "./jbhifi-logo.svg",
      altText: "jbhifi logo",
    },
  },
];
export default ClientStyles;
