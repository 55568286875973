import { Suspense, lazy } from "react";
import Loader from "../page/Loader";
import { getSession, getClient } from "../shared/commonFunctions";

const JbhifiTheme = lazy(() => import("./jbhifiTheme"));
const CnsbTheme = lazy(() => import("./cnsbTheme"));
const BoostTheme = lazy(() => import("./boostTheme"));

let theme = "cnsb";
if (getClient(getSession("ClientInfo").code)[0].theme === "boost") {
  theme = "boost";
} else if (getClient(getSession("ClientInfo").code)[0].theme === "jbhifi") {
  theme = "jbhifi";
}

const ThemeSelector = ({ children }) => {
  return (
    <>
      <Suspense fallback={<>{<Loader />}</>}>
        {theme === "jbhifi" && <JbhifiTheme />}
        {theme === "cnsb" && <CnsbTheme />}
        {theme === "boost" && <BoostTheme />}
        {children}
      </Suspense>
    </>
  );
};

export default ThemeSelector;
