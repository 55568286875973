/* eslint-disable */
import {
  getEnv,
  getSession,
  getClient,
} from "../shared/commonFunctions";
import DOMPurify from "dompurify";

export const goToUrlRegex =
  /^(?:https|myt|myboost|jbhifi):\/\/[\x21\x23-\x3b\x3d\x3f-\x5b\x5d\x5f\x61-\x7b\x7d\x7e]+$/;
var env = getEnv(window.location.host);
env = env ? env : "local";
const mainURL = window.location.origin + window.location.pathname;
const qs = window.location.search;
var clientCode = "b2c-identityportal";

if (qs) {
  let params = new URLSearchParams(qs);
  if (params.get("gotoUrl")) {
    let gotoUrl = "";
    const gotoUrlSearchParam = params.get("gotoUrl");
    if (goToUrlRegex.test(gotoUrlSearchParam)) {
      if (params.get("clientCode")) {
        clientCode = DOMPurify.sanitize(params.get("clientCode"));
        clientCode = clientCode ? clientCode : "b2c-identityportal";
        sessionStorage.setItem("clientCode", JSON.stringify(clientCode))
        var clientData = getClient(clientCode);
        sessionStorage.setItem("ClientInfo", JSON.stringify(clientData[0]))
      }

      gotoUrl = gotoUrlSearchParam;
    }
    sessionStorage.setItem("gotoUrl", JSON.stringify(gotoUrl))
  }
}

if (!getSession("gotoUrl")) {
  sessionStorage.setItem("gotoUrl", JSON.stringify(mainURL))
}

let gotoUrl = getSession("gotoUrl");
clientCode = getSession("clientCode");

const local = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + "/#",
  originUrl: "http://localhost:3000",
  passkeEnable: 'false',
  apiBaseUrl:
    "https://slot2.org002.t-dev.telstra.com/presentation/v1/identity/security/",
  apiBaseUrlV2:
    "https://slot2.org002.t-dev.telstra.com/presentation/v2/identity/security/",
  passcodeApiBaseUrl: "https://slot2.org002.t-dev.telstra.com/v1/identity/",
  defaultClientCode: "b2c-identityportal",
  defaultGoToUrl: "https://fn3.my.np.in.telstra.com.au/mytelstra/",
  logoutUrl:
    "https://fn3.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=" +
    encodeURIComponent(gotoUrl),
  logoutCPUrl:
    "https://fn3.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn1.myid-nonprod.telstra.com/security/?clientCode=" +
    clientCode +
    "%26gotoUrl=" +
    encodeURIComponent(gotoUrl),
  addAccountUrl:
    "https://fn3.myid-nonprod.telstra.com/add-account/?clientCode=" +
    clientCode +
    "&gotoUrl=" +
    encodeURIComponent(gotoUrl),
  changePasswordUrl:
    "https://fn3.myid-nonprod.telstra.com/changepassword#style=cnsb&clientId=b2c-identityportal&clientUrl=https://fn1.myid-nonprod.telstra.com/security/&login_hint=",
  iFrameLogout:
    "https://fn3.myid-nonprod.telstra.com/identity/idp/startSLO.ping",
  iFrameRaaLogout:
    "https://signon-fn3.telstra.com/logout?slosrc=myid&goto=https%3A%2F%2Fsignon-fn3.telstra.com%2FSSOSessionHandler",
};

const uat = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + "/#",
  originUrl: window.location.origin,
  passkeEnable: "bamboo_passkey_enable",
  apiBaseUrl: "bamboo_api_base_url",
  apiBaseUrlV2: "bamboo_api_base_url_v2",
  passcodeApiBaseUrl: "bamboo_passcode_api_base_url",
  defaultClientCode: "b2c-identityportal",
  defaultGoToUrl: "https://fn1.my.np.in.telstra.com.au/mytelstra/",
  logoutUrl:
    "https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=" +
    encodeURIComponent(gotoUrl),
  logoutCPUrl:
    "https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn1.myid-nonprod.telstra.com/security/?clientCode=" +
    clientCode +
    "%26gotoUrl=" +
    encodeURIComponent(gotoUrl),
  addAccountUrl:
    "https://fn1.myid-nonprod.telstra.com/add-account/?clientCode=" +
    clientCode +
    "&gotoUrl=" +
    encodeURIComponent(gotoUrl),
  changePasswordUrl:
    "https://fn1.myid-nonprod.telstra.com/changepassword#style=cnsb&clientId=b2c-identityportal&clientUrl=https://fn1.myid-nonprod.telstra.com/security/&login_hint=",
  iFrameLogout:
    "https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping",
  iFrameRaaLogout:
    "https://signon-fn1.telstra.com/logout?slosrc=myid&goto=https%3A%2F%2Fsignon-fn1.telstra.com%2FSSOSessionHandler",
};

const fn3 = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + "/#",
  originUrl: window.location.origin,
  passkeEnable: "bamboo_passkey_enable",
  apiBaseUrl: "bamboo_api_base_url",
  apiBaseUrlV2: "bamboo_api_base_url_v2",
  passcodeApiBaseUrl: "bamboo_passcode_api_base_url",
  defaultClientCode: "b2c-identityportal",
  defaultGoToUrl: "https://fn3.my.np.in.telstra.com.au/mytelstra/",
  logoutUrl:
    "https://fn3.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=" +
    encodeURIComponent(gotoUrl),
  logoutCPUrl:
    "https://fn3.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn3.myid-nonprod.telstra.com/security/?clientCode=" +
    clientCode +
    "%26gotoUrl=" +
    encodeURIComponent(gotoUrl),
  addAccountUrl:
    "https://fn3.myid-nonprod.telstra.com/add-account/?clientCode=" +
    clientCode +
    "&gotoUrl=" +
    encodeURIComponent(gotoUrl),
  changePasswordUrl:
    "https://fn3.myid-nonprod.telstra.com/changepassword#style=cnsb&clientId=b2c-identityportal&clientUrl=https://fn3.myid-nonprod.telstra.com/security/&login_hint=",
  iFrameLogout:
    "https://fn3.myid-nonprod.telstra.com/identity/idp/startSLO.ping",
  iFrameRaaLogout:
    "https://signon-fn3.telstra.com/logout?slosrc=myid&goto=https%3A%2F%2Fsignon-fn3.telstra.com%2FSSOSessionHandler",
};

const prod = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + "/#",
  originUrl: window.location.origin,
  passkeEnable: "bamboo_passkey_enable",
  apiBaseUrl: "bamboo_api_base_url",
  apiBaseUrlV2: "bamboo_api_base_url_v2",
  passcodeApiBaseUrl: "bamboo_passcode_api_base_url",
  defaultClientCode: "b2c-identityportal",
  logoutCPUrl:
    "https://myid.telstra.com/identity/idp/startSLO.ping?TargetResource=https://myid.telstra.com/security/?clientCode=" +
    clientCode +
    "%26gotoUrl=" +
    encodeURIComponent(gotoUrl),
  defaultGoToUrl: "https://www.myservices.telstra.com.au/mytelstra/",
  logoutUrl:
    "https://myid.telstra.com/identity/idp/startSLO.ping?TargetResource=" +
    encodeURIComponent(gotoUrl),
  addAccountUrl:
    "https://myid.telstra.com/add-account/?clientCode=" +
    clientCode +
    "&gotoUrl=" +
    encodeURIComponent(gotoUrl),
  changePasswordUrl:
    "https://myid.telstra.com/changepassword#style=cnsb&clientId=b2c-identityportal&clientUrl=https://myid.telstra.com/security/&login_hint=",
  iFrameLogout: "https://myid.telstra.com/identity/idp/startSLO.ping",
  iFrameRaaLogout:
    "https://signon.telstra.com/logout?slosrc=myid&goto=https%3A%2F%2Fsignon.telstra.com%2FSSOSessionHandler",
};

const config =
  env === "fn3"
    ? fn3
    : env === "devel" || env === "uat"
    ? uat
    : env === "production"
    ? prod
    : local;
if (qs) {
  let params = new URLSearchParams(qs);
  if (params.get("success")) {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(config.logoutCPUrl);
  }
}

export default { ...config };
