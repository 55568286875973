import { Actions } from '../constants/twoSVDetails'

// *********************************
// NOTE: Fetch MfaStatus
// *********************************

export const fetchMfaStatus = (payload) => {
  return {
    type: Actions.fetchMfaStatus,
    payload
  }
}

export const fetchMfaStatusSuccess = (payload) => {
  return {
    type: Actions.fetchMfaStatusSuccess,
    payload
  };
}

export const fetchMfaStatusError = (payload) => {
  return {
    type: Actions.fetchMfaStatusError,
    payload
  }
}

// *********************************
// NOTE: Fetch Contacts
// *********************************

export const fetchContacts = (payload) => {
  return {
    type: Actions.fetchContacts,
    payload
  }
}

export const fetchContactsSuccess = (payload) => {
  return {
    type: Actions.fetchContactsSuccess,
    payload
  };
}

export const fetchContactsError = (payload) => {
  return {
    type: Actions.fetchContactsError,
    payload
  }
}

// *********************************
// NOTE: Send OTP
// *********************************

export const sendOTP = (payload) => {
  return {
    type: Actions.sendOTP,
    payload
  }
}

export const sendOTPSuccess = (payload) => {
  return {
    type: Actions.sendOTPSuccess,
    payload
  };
}

export const sendOTPError = (payload) => {
  return {
    type: Actions.sendOTPError,
    payload
  }
}

// *********************************
// NOTE: Save Contact Medium
// *********************************

export const saveContactMedium = (payload) => {
  return {
    type: Actions.saveContactMedium,
    payload
  }
}

// *********************************
// NOTE: Verify OTP
// *********************************

export const verifyOTP = (payload) => {
  return {
    type: Actions.verifyOTP,
    payload
  }
}

export const verifyOTPSuccess = (payload) => {
  return {
    type: Actions.verifyOTPSuccess,
    payload
  };
}

export const verifyOTPError = (payload) => {
  return {
    type: Actions.verifyOTPError,
    payload
  }
}

// *********************************
// NOTE: Update Contact
// *********************************

export const updateContacts = (payload) => {
  return {
    type: Actions.updateContacts,
    payload
  }
}

export const updateContactsSuccess = (payload) => {
  return {
    type: Actions.updateContactsSuccess,
    payload
  };
}

export const updateContactsError = (payload) => {
  return {
    type: Actions.updateContactsError,
    payload
  }
}

// *********************************
// NOTE: Get Pin Status
// *********************************

export const fetchPinStatus = (payload) => {
  return {
    type: Actions.fetchPinStatus,
    payload
  }
}

export const fetchPinStatusSuccess = (payload) => {
  return {
    type: Actions.fetchPinStatusSuccess,
    payload
  };
}

export const fetchPinStatusError = (payload) => {
  return {
    type: Actions.fetchPinStatusError,
    payload
  }
}

// *********************************
// NOTE: Create Pin
// *********************************

export const createPin = (payload) => {
  return {
    type: Actions.createPin,
    payload
  }
}

export const createPinSuccess = (payload) => {
  return {
    type: Actions.createPinSuccess,
    payload
  };
}

export const createPinError = (payload) => {
  return {
    type: Actions.createPinError,
    payload
  }
}

// *********************************
// NOTE: Enable MFA
// *********************************

export const mfaEnable = (payload) => {
  return {
    type: Actions.mfaEnable,
    payload
  }
}

export const mfaEnableSuccess = (payload) => {
  return {
    type: Actions.mfaEnableSuccess,
    payload
  };
}

export const mfaEnableError = (payload) => {
  return {
    type: Actions.mfaEnableError,
    payload
  }
}

// *********************************
// NOTE: Disable MFA
// *********************************

export const mfaDisable = (payload) => {
  return {
    type: Actions.mfaDisable,
    payload
  }
}

export const mfaDisableSuccess = (payload) => {
  return {
    type: Actions.mfaDisableSuccess,
    payload
  };
}

export const mfaDisableError = (payload) => {
  return {
    type: Actions.mfaDisableError,
    payload
  }
}

// *********************************
// NOTE: Save Action and Arguments
// *********************************

export const saveActionAndArgs = (payload) => {
  return {
    type: Actions.saveActionAndArgs,
    payload
  }
}

// *********************************
// NOTE: Set Domain Error Values
// *********************************

export const setDomainErrorValues = (payload) => {
  return {
    type: Actions.setDomainErrorValues,
    payload
  }
}

// *********************************
// NOTE: Fetch Trusted Devices
// *********************************

export const fetchTrustedDevices = (payload) => {
  return {
    type: Actions.fetchTrustedDevices,
    payload
  }
}

export const fetchTrustedDevicesSuccess = (payload) => {
  return {
    type: Actions.fetchTrustedDevicesSuccess,
    payload
  };
}

export const fetchTrustedDevicesError = (payload) => {
  return {
    type: Actions.fetchTrustedDevicesError,
    payload
  }
}

// *********************************
// NOTE: Revoke Trusted Devices
// *********************************

export const revokeTrustedDevices = (payload) => {
  return {
    type: Actions.revokeTrustedDevices,
    payload
  }
}

export const revokeTrustedDevicesSuccess = (payload) => {
  return {
    type: Actions.revokeTrustedDevicesSuccess,
    payload
  };
}

export const revokeTrustedDevicesError = (payload) => {
  return {
    type: Actions.revokeTrustedDevicesError,
    payload
  }
}