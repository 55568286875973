import { Actions } from '../constants/manageTdi';

const initialState = {
  isLoading: false,
  TDIUserList:[],
  delinkedSuccess: false,
  delinkUserData: {},

}

export const TDIUser = (state = initialState, action) => {
  switch (action.type) {
    case Actions.fetchTDIUsersStart: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.fetchTDIUsersSuccess: {
        return {
          ...state,
          isLoading: false,
          TDIUserList: action.payload.data
        }
      }
      case Actions.fetchTDIUsersError: {
        return {
          ...state,
          isLoading: false,
          TDIUserList: [],
          delinkedSuccess: false,
        }
      }
      case Actions.deLinkTDIUserStart: {
        return {
          ...state,
          isLoading: true,
          delinkedSuccess: false,
          delinkUserData: action.payload.req
        }
      }
      case Actions.deLinkTDIUsersSuccess: {
        return {
          ...state,
          isLoading: false,
          delinkedSuccess: true,
        }
      }
      case Actions.deLinkTDIUserError: {
        return {
          ...state,
          isLoading: false,
          delinkedSuccess: false,
        }
      }
    default:
      return state
  }
}