export const Actions = {
    fetchTDIUsersStart:'FETCH_TDI_USERS_START',
    fetchTDIUsersSuccess:'FETCH_TDI_USERS_SUCCESS',
    fetchTDIUsersError:'FETCH_TDI_USERS_Error',
    fetchMfaStatusSuccess:'FETCH_MFA_STATUS_SUCCESS',
    deLinkTDIUserStart: 'DELINK_TDI_USER_START',
    deLinkTDIUsersSuccess: 'DELINK_TDI_USER_SUCCESS',
    deLinkTDIUserError: 'DELINK_TDI_USER_ERROR',
    postChangeUserName: "POST_CHANGE_USER_NAME",
    postChangeUserNameStart: "POST_CHANGE_USER_NAME_START",
    postChangeUserNameSuccess: "POST_CHANGE_USER_NAME_SUCCESS",
    postChangeUserNameError: "POST_CHANGE_USER_NAME_ERROR",
    setChangeUserNameData: "SET_CHANGE_USER_NAME_DATA"
  }