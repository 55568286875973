export const Actions = {
  fetchMfaStatus:'FETCH_MFA_STATUS',
  fetchMfaStatusSuccess:'FETCH_MFA_STATUS_SUCCESS',
  fetchMfaStatusError:'FETCH_MFA_STATUS_ERROR',
  fetchContacts: 'FETCH_CONTACTS',
  fetchContactsSuccess: 'FETCH_CONTACTS_SUCCESS',
  fetchContactsError: 'FETCH_CONTACTS_ERROR',
  sendOTP: 'SEND_OTP',
  sendOTPSuccess: 'SEND_OTP_SUCCESS',
  sendOTPError: 'SEND_OTP_ERROR',
  saveContactMedium: 'SAVE_CONTACT_MEDIUM',
  verifyOTP: 'VERIFY_OTP',
  verifyOTPSuccess: 'VERIFY_OTP_SUCCESS',
  verifyOTPError: 'VERIFY_OTP_ERROR',
  updateContacts: 'UPDATE_CONTACTS',
  updateContactsSuccess: 'UPDATE_CONTACTS_SUCCESS',
  updateContactsError: 'UPDATE_CONTACTS_ERROR',
  mfaEnable: 'MFA_ENABLE',
  mfaEnableSuccess: 'MFA_ENABLE_SUCCESS',
  mfaEnableError: 'MFA_ENABLE_ERROR',
  mfaDisable: 'MFA_DISABLE',
  mfaDisableSuccess: 'MFA_DISABLE_SUCCESS',
  mfaDisableError: 'MFA_DISABLE_ERROR',
  fetchPinStatus: 'FETCH_PIN_STATUS',
  fetchPinStatusSuccess: 'FETCH_PIN_STATUS_SUCCESS',
  fetchPinStatusError: 'FETCH_PIN_STATUS_ERROR',
  createPin: 'CREATE_PIN',
  createPinSuccess: 'CREATE_PIN_SUCCESS',
  createPinError: 'CREATE_PIN_ERROR',
  saveActionAndArgs: 'SAVE_ACTION_AND_ARGS',
  setDomainErrorValues: 'SET_DOMAIN_ERROR_VALUES',
  fetchTrustedDevices:'FETCH_TRUSTED_DEVICES',
  fetchTrustedDevicesSuccess:'FETCH_TRUSTED_DEVICES_SUCCESS',
  fetchTrustedDevicesError:'FETCH_REVOKE_TRUSTED_DEVICES_ERROR',
  revokeTrustedDevices:'REVOKE_TRUSTED_DEVICES',
  revokeTrustedDevicesSuccess:'REVOKE_TRUSTED_DEVICES_SUCCESS',
  revokeTrustedDevicesError:'REVOKE_TRUSTED_DEVICES_ERROR',
}