import React from "react";
import { Spacing, TextStyle } from "@able/react";
import DOMPurify from "dompurify";

const PageTitle = React.forwardRef((props, ref) => {
  const { title, top = "", bottom = "", ...rest } = props;

  return (
    <Spacing className={["px-0"]} top={top} bottom={bottom}>
      <TextStyle alias="HeadingB" element="h1" {...rest}>
        <span tabIndex="-1" ref={ref}>{DOMPurify.sanitize(title)}</span>
      </TextStyle>
    </Spacing>
  );
});
export default PageTitle;
