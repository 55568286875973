import { Actions } from "../constants/managePasskey";

export const fetchCreatePasskeyOptions = (history) => {
  return {
    type: Actions.FETCH_CREATE_PASSKEY_OPTIONS,
    history,
  };
};
export const fetchCreatePasskeyOptionsStart = (payload) => {
  return {
    type: Actions.FETCH_CREATE_PASSKEY_OPTIONS_START,
    payload,
  };
};
export const fetchCreatePasskeyOptionsSuccess = (payload) => {
  return {
    type: Actions.FETCH_CREATE_PASSKEY_OPTIONS_SUCCESS,
    payload,
  };
};
export const fetchCreatePasskeyOptionsError = (payload) => {
  return {
    type: Actions.FETCH_CREATE_PASSKEY_OPTIONS_ERROR,
    payload,
  };
};

export const fetchPasskeyList = (history) => {
  return {
    type: Actions.FETCH_PASSKEY_LIST,
    history,
  };
};
export const fetchPasskeyListStart = (payload) => {
  return {
    type: Actions.FETCH_PASSKEY_LIST_START,
    payload,
  };
};
export const fetchPasskeyListSuccess = (payload) => {
  return {
    type: Actions.FETCH_PASSKEY_LIST_SUCCESS,
    payload,
  };
};
export const fetchPasskeyListError = (payload) => {
  return {
    type: Actions.FETCH_PASSKEY_LIST_ERROR,
    payload,
  };
};

export const postCreatePasskeyOptions = (payload, history, postError) => {
  return {
    type: Actions.POST_CREATE_PASSKEY_OPTIONS,
    payload,
    history,
    postError,
  };
};
export const postCreatePasskeyOptionsStart = (payload) => {
  return {
    type: Actions.POST_CREATE_PASSKEY_OPTIONS_START,
    payload,
  };
};
export const postCreatePasskeyOptionsSuccess = (payload) => {
  return {
    type: Actions.POST_CREATE_PASSKEY_OPTIONS_SUCCESS,
    payload,
  };
};
export const clearPasskeySuccessMessage = () => {
  return {
    type: Actions.CLEAR_PASSKEY_SUCCESS_MESSAGE,
  };
}
export const postCreatePasskeyOptionsError = (payload) => {
  return {
    type: Actions.POST_CREATE_PASSKEY_OPTIONS_ERROR,
    payload,
  };
};

export const postEditedPasskeyData = (payload, history) => {
  return {
    type: Actions.POST_EDITED_PASSKEY_DATA,
    payload,
    history,
  };
};
export const postEditedPasskeyDataStart = (payload) => {
  return {
    type: Actions.POST_EDITED_PASSKEY_DATA_START,
    payload,
  };
};
export const postEditedPasskeyDataSuccess = (payload) => {
  return {
    type: Actions.POST_EDITED_PASSKEY_DATA_SUCCESS,
    payload,
  };
};
export const postEditedPasskeyDataError = (payload) => {
  return {
    type: Actions.POST_EDITED_PASSKEY_DATA_ERROR,
    payload,
  };
};

export const postDeletePasskeyData = (payload, history) => {
  return {
    type: Actions.POST_DELETE_PASSKEY_DATA,
    payload,
    history,
  };
};
export const postDeletePasskeyDataStart = (payload) => {
  return {
    type: Actions.POST_DELETE_PASSKEY_DATA_START,
    payload,
  };
};
export const postDeletePasskeyDataSuccess = (payload) => {
  return {
    type: Actions.POST_DELETE_PASSKEY_DATA_SUCCESS,
    payload,
  };
};
export const postDeletePasskeyDataError = (payload) => {
  return {
    type: Actions.POST_DELETE_PASSKEY_DATA_ERROR,
    payload,
  };
};
