import { getEnv } from '../shared/commonFunctions';
import config from "./config";

var env = getEnv(window.location.host);
const clientData = {
  // clientId: 'fidoapp-idx-mobile-android',
  clientId: 'b2c-identityportal',
  clientScope: 'openid'
}

const localConstants =  {
  stsAuthority : 'https://fn1.myid-nonprod.telstra.com/identity',
  clientId : clientData.clientId,
  clientRoot : 'http://localhost:3000/',
  clientScope : clientData.clientScope,
  logout : 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn1.my.np.in.telstra.com.au/mytelstra/',
  apiRoot : 'https://fn1.myid-nonprod.telstra.com/identity/',
}

const uatConstants = {
  stsAuthority : 'https://fn1.myid-nonprod.telstra.com/identity',
  clientId : clientData.clientId,
  clientRoot : config.originUrl + '/security/',
  clientScope : clientData.clientScope,
  logout : config.logoutUrl,
  apiRoot : 'https://fn1.myid-nonprod.telstra.com/identity/'
}

const fn3Constants = {
  stsAuthority : 'https://fn3.myid-nonprod.telstra.com/identity',
  clientId : clientData.clientId,
  clientRoot : config.originUrl + '/security/',
  clientScope : clientData.clientScope,
  logout : config.logoutUrl,
  apiRoot : 'https://fn3.myid-nonprod.telstra.com/identity/'
}

const prodConstants = {
  stsAuthority : 'https://myid.telstra.com/identity',
  clientId : clientData.clientId,
  clientRoot : config.originUrl + '/security/',
  clientScope : clientData.clientScope,
  logout : 'https://myid.telstra.com/identity/idp/startSLO.ping?TargetResource=https://www.myservices.telstra.com.au/mytelstra/',
  apiRoot : 'https://myid.telstra.com/identity/',
}

const constants = env === 'local' ? localConstants : env === 'uat' ? uatConstants : env === 'fn3' ? fn3Constants : prodConstants;

export default { ...constants };