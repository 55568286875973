import React from "react";
import { TextStyle } from "@able/react";

function Loader({ loading }) {
  return (
    <>
      <div className="signinLoader">
        <div>
          <div className="spinner-box">
            <div className="round-spinner"></div>
          </div>
          <div className="t-text-center">
            <TextStyle alias="Base" element="p" className="px-0">
              Loading ...
            </TextStyle>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader;
