import { call, put, takeLatest } from "redux-saga/effects";
import { Actions } from "../constants/twoSVDetails";
import url from "../helpers/config";
import {
  fetchContactsSuccess,
  fetchContactsError,
  fetchMfaStatus,
  fetchMfaStatusSuccess,
  fetchMfaStatusError,
  sendOTPSuccess,
  sendOTPError,
  verifyOTPSuccess,
  verifyOTPError,
  updateContactsSuccess,
  updateContactsError,
  fetchPinStatusSuccess,
  fetchPinStatusError,
  createPinSuccess,
  createPinError,
  mfaEnableSuccess,
  mfaEnableError,
  mfaDisableSuccess,
  mfaDisableError,
  updateContacts,
  fetchTrustedDevices,
  fetchTrustedDevicesSuccess,
  fetchTrustedDevicesError,
  revokeTrustedDevices,
  revokeTrustedDevicesSuccess,
  revokeTrustedDevicesError,
} from "../actions/twoSVDetails";
import { getSession } from "../shared/commonFunctions";
import { serverErrors } from "../helpers/messages";
import { otherMsgs } from "../helpers/messages";
import axios from "axios";

const sleep = ms => new Promise(resolve => setTimeout(() => resolve(), ms))

function* getMfaStatus(action) {
  let history = action.payload;
  const accessToken = getSession("AuthData").access_token;
  try {
    const { data } = yield call(axios.get, url.apiBaseUrl + "mfastatus", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        x_goto_url: `https://my-uat.telstra.com.au/mytelstra/dashboard`,
        x_clientcode: `test`,
      },
    });
    if (data.status === 200) {
      if (data.data.cipMfaEnabled === "true") {
        yield put(fetchMfaStatusSuccess(true));
      } else if (data.data.cipMfaEnabled === "false") {
        yield put(fetchMfaStatusSuccess(false));
      }
      history.push("/settings");
    } else {
      yield put(fetchMfaStatusError(data));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(fetchMfaStatusError({ code: resData.status }));
    } else {
      if (resData.status === 403 && resData.errors[0].code === 675) {
        // Checking error code err_403_675(stepup usesr)
        let err =
          "err_" +
          resData.status +
          (resData.errors ? "_" + resData.errors[0].code : "");
        yield put(fetchMfaStatusError(serverErrors[err]));
      } else {
        yield put(fetchMfaStatusError(serverErrors.err_default));
      }
      history.push("/error");
    }
  }
}

function* getContacts(action) {
  let history = action.payload.history;
  const accessToken = getSession("AuthData").access_token;
  let checkUnlinked = action.payload.checkUnlinked;
  try {
    const response = yield call(axios.get, url.apiBaseUrl + "contacts", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      if (checkUnlinked) {
        yield put(fetchContactsSuccess(response.data.data));
        yield put(fetchMfaStatus(history));
      } else {
        yield put(fetchContactsSuccess(response.data.data));
        history.push("/settings/contacts");
      }
    } else {
      yield put(fetchContactsError(serverErrors.err_default));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(fetchContactsError({ code: resData.status }));
    } else {
      if (resData.status === 422 && resData.errors[0].code === 3001) {
        // Handling 3001 error codes in status code 422 error
        if (checkUnlinked) {
          yield put(fetchContactsSuccess(resData.data));
          yield put(fetchMfaStatus(history));
        } else {
          yield put(fetchContactsSuccess(resData.data));
          history.push("/settings/contacts");
        }
      } else {
        if (
          (resData.status === 403 && resData.errors[0].code === 675) ||
          (resData.status === 422 && resData.errors[0].code === 3000)
        ) {
          // Checking error codes err_403_675(stepup usesr), err_422_3000(unlinked user)
          let err =
            "err_" +
            resData.status +
            (resData.errors ? "_" + resData.errors[0].code : "");
          yield put(fetchContactsError(serverErrors[err]));
        } else {
          yield put(fetchContactsError(serverErrors.err_default));
        }
        history.push("/error");
      }
    }
  }
}

function* postOTP(action) {
  // api call to send otp
  const req = action.payload.postData;
  let history = action.payload.history;
  const accessToken = getSession("AuthData").access_token;
  try {
    const response = yield call(axios.post, url.apiBaseUrl + "sendotp", req, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response && response.data.status === 200) {
      if (response.data.data.oneTimePinStatus === "ACTIVE") {
        yield put(sendOTPSuccess(true));
        history.push("/settings/verify");
      }
    } else {
      yield put(sendOTPError(response.data));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(sendOTPError({ code: resData.status }));
    } else {
      if (resData.status === 400) {
        // Handling domain errors
        if (resData.errors[0].code === 112) {
          // top level domain error
          yield put(
            sendOTPError({
              domainErrorStatus: true,
              domainErrorMsg: otherMsgs.topLevelDomain,
            })
          );
        } else if (resData.errors[0].code === 113) {
          // black listed domain error
          yield put(
            sendOTPError({
              domainErrorStatus: true,
              domainErrorMsg: otherMsgs.blackListDomain,
            })
          );
        } else if (resData.errors[0].code === 114) {
          // alert domain error
          yield put(
            sendOTPError({
              domainErrorStatus: true,
              domainErrorMsg: otherMsgs.alertDomain,
            })
          );
        } else {
          yield put(sendOTPError(serverErrors.err_default));
          history.push("/error");
        }
      } else {
        if (
          (resData.status === 422 && resData.errors[0].code === 2005) ||
          (resData.status === 422 && resData.errors[0].code === 2004)
        ) {
          // Handling status code 422 and error code 2005(maximum send otp reached) and error code 2004(maximum verify otp reached)
          yield put(sendOTPError(serverErrors.err_default));
          history.push("/lockout");
        } else {
          if (resData.status === 403 && resData.errors[0].code === 675) {
            // Checking error code err_403_675(stepup usesr)
            let err =
              "err_" +
              resData.status +
              (resData.errors ? "_" + resData.errors[0].code : "");
            yield put(sendOTPError(serverErrors[err]));
          } else {
            yield put(sendOTPError(serverErrors.err_default));
          }
          history.push("/error");
        }
      }
    }
  }
}

function* postVerifyOTP(action) {
  // api call to verify otp
  const req = action.payload.postData;
  let history = action.payload.history;
  let updatePostData = action.payload.updatePostData;
  const accessToken = getSession("AuthData").access_token;
  try {
    const response = yield call(axios.post, url.apiBaseUrl + "verifyotp", req, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response && response.data.status === 200) {
      if (response.data.data.oneTimePinVerificationStatus === "ACTIVE") {
        yield put(verifyOTPSuccess(true));
        yield put(
          updateContacts({ postData: updatePostData, history: history })
        );
      }
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(verifyOTPError({ code: resData.status }));
    } else {
      if (resData.status === 422) {
        // Handling status code 422 error
        if (resData.errors[0].code === 2002) {
          // Handling error code 2002(otp not matched)
          yield put(
            verifyOTPError({
              verifyStatus: false,
              remainingCount: resData.oneTimePinData.attemptRemaining,
              verifyOtpmsg: otherMsgs.verifyOtpInvalid.replace(
                "&&NoOfAttempts&&",
                resData.oneTimePinData.attemptRemaining
              ),
            })
          );
        } else if (resData.errors[0].code === 2003) {
          // Handling error code 2003(otp expired)
          yield put(
            verifyOTPError({
              verifyStatus: false,
              remainingCount: null,
              verifyOtpmsg: otherMsgs.verifyOtpExpired,
            })
          );
        } else if (resData.errors[0].code === 2004) {
          // Handling error code 2004(maximum otp attempts completed)
          yield put(verifyOTPError({ resData }));
          history.push("/lockout");
        }
      } else {
        if (resData.status === 403 && resData.errors[0].code === 675) {
          // Checking error code err_403_675(stepup user)
          let err =
            "err_" +
            resData.status +
            (resData.errors ? "_" + resData.errors[0].code : "");
          yield put(verifyOTPError(serverErrors[err]));
        } else {
          yield put(verifyOTPError(serverErrors.err_default));
        }
        history.push("/error");
      }
    }
  }
}

function* postUpdateContacts(action) {
  const req = action.payload.postData;
  const history = action.payload.history;
  const accessToken = getSession("AuthData").access_token;
  try {
    const response = yield call(
      axios.post,
      url.apiBaseUrl + "updatecontacts",
      req,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response && response.data.status === 200) {
      yield put(updateContactsSuccess(true));
      history.push("/settings/splash");
    } else {
      yield put(updateContactsError(response.data));
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(updateContactsError({ code: resData.status }));
    } else {
      if (resData.status === 400) {
        // Handling domain errors
        if (resData.errors[0].code === 112) {
          // top level domain error
          yield put(
            updateContactsError({
              domainErrorStatus: true,
              domainErrorMsg: otherMsgs.topLevelDomain,
            })
          );
        } else if (resData.errors[0].code === 113) {
          // black listed domain error
          yield put(
            updateContactsError({
              domainErrorStatus: true,
              domainErrorMsg: otherMsgs.blackListDomain,
            })
          );
        } else if (resData.errors[0].code === 114) {
          // alert domain error
          yield put(
            updateContactsError({
              domainErrorStatus: true,
              domainErrorMsg: otherMsgs.alertDomain,
            })
          );
        } else {
          yield put(updateContactsError(serverErrors.err_default));
          history.push("/error");
        }
      } else {
        if (
          (resData.status === 403 && resData.errors[0].code === 675) ||
          (resData.status === 422 && resData.errors[0].code === 3100)
        ) {
          // checking for error codes err_403_675(stepup usesr), err_422_3100(unlinked user)
          let err =
            "err_" +
            resData.status +
            (resData.errors ? "_" + resData.errors[0].code : "");
          yield put(updateContactsError(serverErrors[err]));
        } else {
          yield put(updateContactsError(serverErrors.err_default));
        }
        history.push("/error");
      }
    }
  }
}

function* getPinStatus(action) {
  // api call to know the authenticators(passcode) of user
  let history = action.payload;
  const accessToken = getSession("AuthData").access_token;
  try {
    const response = yield call(
      axios.get,
      url.passcodeApiBaseUrl + "fido/authenticators",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          appid: `FIDOApp`, // to be changed to 'appId': `MyTelstra`,
          x_passcode_status: `true`,
        },
      }
    );
    if (response.status === 200) {
      if (response.data.authenticatorDataInfoMap.passcode) {
        yield put(fetchPinStatusSuccess({ status: true, value: "old-pin" }));
        history.push("/settings/pin-success");
      } else {
        yield put(fetchPinStatusSuccess({ status: false, value: "" }));
        history.push("/settings/setup-pin");
      }
    } else {
      yield put(fetchPinStatusError(response.data));
      history.push("/error");
    }
  } catch (e) {
    let res = e.response;
    if (res.status === 400) {
      yield put(fetchPinStatusSuccess({ status: false, value: "" }));
      history.push("/settings/setup-pin");
    } else if (res.status === 401) {
      // Handling status code 401 error
      yield put(fetchPinStatusError({ code: res.status }));
    } else {
      // Handling status code 403, 404, 500 error and remaining error status codes.
      yield put(fetchPinStatusError(serverErrors.err_default));
      history.push("/error");
    }
  }
}

function* setPin(action) {
  // api call to create passcode(telstra pin) for the user
  const req = action.payload.postData;
  let history = action.payload.history;
  const accessToken = getSession("AuthData").access_token;
  try {
    const response = yield call(
      axios.post,
      url.passcodeApiBaseUrl + "passcode/create",
      req,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // to be changed to 'appId': `MyTelstra`,
          appid: `FIDOApp`,
        },
      }
    );
    if (response.status === 200) {
      yield put(createPinSuccess({ status: true, value: "new-pin" }));
      history.push("/settings/pin-success");
    } else {
      yield put(createPinError(response.data));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response.data;
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(createPinError({ code: resData.status }));
    } else {
      if (resData.status === 400) {
        // Handling status code 400 error
        if (resData.errors[0].code === 916 || resData.errors[0].code === 911) {
          // Handling error code 916, 911 (top alert:916->pin format not allowed, 911->pin lengtht is less than 4)
          yield put(createPinError({ status: false, value: "" }));
        } else {
          if (
            resData.errors[0].code === 614 ||
            resData.errors[0].code === 625 ||
            resData.errors[0].code === 675
          ) {
            // Handling error code 614, 625, 675 (top alert:pin format not allowed)
            let err =
              "passcode_err_" +
              resData.status +
              (resData.errors ? "_" + resData.errors[0].code : "");
            yield put(createPinError(serverErrors[err]));
          } else {
            yield put(createPinError(serverErrors.err_default));
          }
          history.push("/error");
        }
      } else {
        yield put(createPinError(serverErrors.err_default));
        history.push("/error");
      }
    }
  }
}

function* setMfaEnable(action) {
  let history = action.payload;
  const accessToken = getSession("AuthData").access_token;
  try {
    const { data } = yield call(axios.post, url.apiBaseUrl + "enablemfa", "", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (data.status === 200) {
      if (data.data.cipMfaEnabled === "true") {
        yield call(sleep, 500);
        yield put(mfaEnableSuccess(true));
        yield put(fetchMfaStatusSuccess(true));
        history.push("/settings");
      } else {
        yield put(mfaEnableError(data));
        history.push("/error");
      }
    } else {
      yield put(mfaEnableError(data));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(fetchMfaStatusError({ code: resData.status }));
    } else {
      if (resData.status === 403 && resData.errors[0].code === 675) {
        // Checking error code err_403_675(stepup usesr)
        let err =
          "err_" +
          resData.status +
          (resData.errors ? "_" + resData.errors[0].code : "");
        yield put(fetchMfaStatusError(serverErrors[err]));
      } else {
        yield put(fetchMfaStatusError(serverErrors.err_default));
      }
      history.push("/error");
    }
  }
}

function* setMfaDisable(action) {
  let history = action.payload;
  const accessToken = getSession("AuthData").access_token;
  try {
    const { data } = yield call(axios.post, url.apiBaseUrl + "disablemfa", "", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (data.status === 200) {
      if (data.data.cipMfaEnabled === "false") {
        yield put(mfaDisableSuccess(true));
        yield put(fetchMfaStatusSuccess(false));
        history.push("/settings");
      } else {
        yield put(mfaDisableError(data));
        history.push("/error");
      }
    } else {
      yield put(mfaDisableError(data));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(fetchMfaStatusError({ code: resData.status }));
    } else {
      if (resData.status === 403 && resData.errors[0].code === 675) {
        // Checking error code err_403_675(stepup usesr)
        let err =
          "err_" +
          resData.status +
          (resData.errors ? "_" + resData.errors[0].code : "");
        yield put(fetchMfaStatusError(serverErrors[err]));
      } else {
        yield put(fetchMfaStatusError(serverErrors.err_default));
      }
      history.push("/error");
    }
  }
}

function* getTrustedDevices(action) {
  let history = action.payload;
  const accessToken = getSession("AuthData").access_token;

  try {
    const { data } = yield call(
      axios.get,
      // url.apiBaseUrl + 'trusteddevices',
      url.apiBaseUrl + "extract-trusted-devices",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          // 'x_goto_url': `https://my-uat.telstra.com.au/mytelstra/dashboard`,
          // 'x_clientcode': `test`
        },
      }
    );
    // data = emptyData;
    if (data.status === 200) {
      // console.log(data);
      yield put(fetchTrustedDevicesSuccess(data));
      history.push("/devices");
    } else {
      yield put(fetchTrustedDevicesError(data));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(fetchTrustedDevicesError({ code: resData.status }));
    } else {
      if (resData.status === 422 && resData.errors[0].code === 5000) {
        // Checking error code err_403_675(stepup usesr)
        yield put(fetchTrustedDevicesSuccess({ Trustdevicedetails: [] }));
        history.push("/devices");
      } else if (resData.status === 403 && resData.errors[0].code === 675) {
        // Checking error code err_403_675(stepup usesr)
        let err =
          "err_" +
          resData.status +
          (resData.errors ? "_" + resData.errors[0].code : "");
        yield put(fetchTrustedDevicesError(serverErrors[err]));
        history.push("/error");
      } else {
        yield put(fetchTrustedDevicesError(serverErrors.err_default));
        history.push("/error");
      }
    }
  }
}

function* setRevokeTrustedDevices(action) {
  const req = action.payload.postData;
  const accessToken = getSession("AuthData").access_token;
  let history = action.payload.history;

  try {
    const { data } = yield call(
      axios.post,
      // url.apiBaseUrl + 'revokeAll',
      url.apiBaseUrl + "revoke-trusted-devices",
      req,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          // 'x_goto_url': `https://my-uat.telstra.com.au/mytelstra/dashboard`,
          // 'x_clientcode': `test`
        },
      }
    );
    // data = sampleData;
    if (data.status === 200) {
      //we are not getting revoked device count so we are storing count of devices
      yield put(
        revokeTrustedDevicesSuccess({
          revoked: true,
          revokedDevices: action.payload.postData.fingerprintId,
        })
      );
      history.push("/devices");
    } else {
      yield put(revokeTrustedDevicesError(data));
      history.push("/error");
    }
  } catch (e) {
    let resData = e.response && e.response.data ? e.response.data : "";
    if (resData.status === 401) {
      // Handling status code 401 error
      yield put(revokeTrustedDevicesError({ code: resData.status }));
    } else {
      if (resData.status === 403 && resData.errors[0].code === 675) {
        // Checking error code err_403_675(stepup usesr)
        let err =
          "err_" +
          resData.status +
          (resData.errors ? "_" + resData.errors[0].code : "");
        yield put(revokeTrustedDevicesError(serverErrors[err]));
      } else {
        yield put(revokeTrustedDevicesError(serverErrors.err_default));
      }
      history.push("/error");
    }
  }
}

export default function* twoSVDetails() {
  yield takeLatest(Actions.fetchMfaStatus, getMfaStatus);
  yield takeLatest(Actions.fetchContacts, getContacts);
  yield takeLatest(Actions.sendOTP, postOTP);
  yield takeLatest(Actions.verifyOTP, postVerifyOTP);
  yield takeLatest(Actions.updateContacts, postUpdateContacts);
  yield takeLatest(Actions.fetchPinStatus, getPinStatus);
  yield takeLatest(Actions.createPin, setPin);
  yield takeLatest(Actions.mfaEnable, setMfaEnable);
  yield takeLatest(Actions.mfaDisable, setMfaDisable);
  yield takeLatest(Actions.fetchTrustedDevices, getTrustedDevices);
  yield takeLatest(Actions.revokeTrustedDevices, setRevokeTrustedDevices);
}
