// import React, { Suspense} from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import importComponents from "./importComponents";

function RootRoute() {

  return (
    <Router>
      <Switch>
        { importComponents.map(({path,component})=> 
          <Route exact path={path} component={component} key={component} />
        )}
      </Switch>
    </Router>
  );
}

export default RootRoute;
