import { call, put, takeLatest } from "redux-saga/effects";
import { Actions } from "../constants/managePasskey";
import url from "../helpers/config";
import { getSession } from "../shared/commonFunctions";
import { updateAnalyticsData } from "../helpers/analytics";
import axios from "axios";
import {
  fetchCreatePasskeyOptionsStart,
  fetchCreatePasskeyOptionsSuccess,
  fetchCreatePasskeyOptionsError,
  postCreatePasskeyOptionsStart,
  postCreatePasskeyOptionsSuccess,
  postCreatePasskeyOptionsError,
  postEditedPasskeyDataStart,
  postEditedPasskeyDataSuccess,
  postEditedPasskeyDataError,
  postDeletePasskeyDataStart,
  postDeletePasskeyDataSuccess,
  postDeletePasskeyDataError,
  fetchPasskeyListSuccess,
  fetchPasskeyListError,
  fetchPasskeyListStart,
} from "../actions/managePasskeyActions";

function* getCreatePassKeyOptions(action) {
  let { history } = action;
  try {
    yield put(fetchCreatePasskeyOptionsStart());
    const data = yield call(axios.get, url.apiBaseUrlV2 + "passkey-create", {
      headers: {
        Authorization: `Bearer ${getSession("AuthData").access_token}`,
        "Content-Type": "application/json",
      },
    });
    if (data.status === 200) {
      yield put(fetchCreatePasskeyOptionsSuccess(data.data));
    } else {
      yield put(fetchCreatePasskeyOptionsError());
      history.push("/manage-passkey/error");
    }
  } catch (e) {
    yield put(fetchCreatePasskeyOptionsError());
    updateAnalyticsData("Passkey", null, {
      eventInfo: {
        eventAction: "error",
        eventType: "create passkey",
        eventCategory: "failed",
        eventName: "get options failed",
      },
      attributes: {},
    });
    history.push("/manage-passkey/error");
  }
}

function* postCreatePassKeyOptions(action) {
  let { history, postError } = action;
  try {
    if (!postError) {
      yield put(postCreatePasskeyOptionsStart());
    }

    const data = yield call(
      axios.post,
      url.apiBaseUrlV2 + "passkey-creds",
      {
        ...action.payload,
        clientCode: getSession("clientCode"),
      },
      {
        headers: {
          Authorization: `Bearer ${getSession("AuthData").access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!postError) {
      if (data.status === 201) {
        yield put(postCreatePasskeyOptionsSuccess(data.data));
      } else {
        yield put(postCreatePasskeyOptionsError());
        history.push("/manage-passkey/error");
      }
    }
  } catch (e) {
    updateAnalyticsData("Passkey", null, {
      eventInfo: {
        eventAction: "error",
        eventType: "create passkey",
        eventCategory: "failed",
        eventName: "Post create passkey",
      },
      attributes: {},
    });
    if (!postError) {
      yield put(postCreatePasskeyOptionsError());
      history.push("/manage-passkey/error");
    }
  }
}

function* postEditPassKeyOptions(action) {
  let { history, payload } = action;
  try {
    yield put(postEditedPasskeyDataStart());
    const data = yield call(
      axios.patch,
      url.apiBaseUrlV2 + `passkey-creds/${payload.id}`,
      {
        nickname: payload.nickname,
      },
      {
        headers: {
          Authorization: `Bearer ${getSession("AuthData").access_token}`,
          "Content-Type": "application/json-patch+json",
        },
      }
    );
    if (data.status === 204) {
      yield put(postEditedPasskeyDataSuccess(payload));
    } else {
      yield put(postEditedPasskeyDataError());
    }
  } catch (e) {
    yield put(postEditedPasskeyDataError());
  }
}

function* postDeletePassKeyOptions(action) {
  let { history, payload } = action;
  try {
    yield put(postDeletePasskeyDataStart());
    const data = yield call(
      axios.delete,
      url.apiBaseUrlV2 + `passkey-creds/${payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${getSession("AuthData").access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (data.status === 204) {
      yield put(postDeletePasskeyDataSuccess(payload));
    } else {
      yield put(postDeletePasskeyDataError());
    }
  } catch (e) {
    yield put(postDeletePasskeyDataError());
  }
}
function* getPassKeyList(action) {
  let { history } = action;
  try {
    yield put(fetchPasskeyListStart());
    const data = yield call(axios.get, url.apiBaseUrlV2 + "passkey-creds", {
      headers: {
        Authorization: `Bearer ${getSession("AuthData").access_token}`,
        "Content-Type": "application/json",
      },
    });
    if (data.status === 200) {
      yield put(fetchPasskeyListSuccess(data.data));
    } else {
      yield put(fetchPasskeyListError());
      history.push("/manage-passkey/error");
    }
  } catch (e) {
    yield put(fetchPasskeyListError());
    updateAnalyticsData("Passkey", null, {
      eventInfo: {
        eventAction: "error",
        eventType: "get passkey",
        eventCategory: "failed",
        eventName: "get passkeys failed",
      },
      attributes: {},
    });
    history.push("/manage-passkey/error");
  }
}

export default function* managePassKey() {
  yield takeLatest(
    Actions.FETCH_CREATE_PASSKEY_OPTIONS,
    getCreatePassKeyOptions
  );
  yield takeLatest(
    Actions.POST_CREATE_PASSKEY_OPTIONS,
    postCreatePassKeyOptions
  );
  yield takeLatest(Actions.POST_EDITED_PASSKEY_DATA, postEditPassKeyOptions);
  yield takeLatest(Actions.POST_DELETE_PASSKEY_DATA, postDeletePassKeyOptions);
  yield takeLatest(Actions.FETCH_PASSKEY_LIST, getPassKeyList);
}
