import React, { useRef, useEffect } from "react";
import { Spacing, TextStyle, ActionButton } from "@able/react";
import initAnalytics, { updateAnalyticsData } from "../../helpers/analytics";
import { routeInfo } from "../../helpers";
import config from "../../helpers/config";
import PageTitle from "../../components/PageTitle";
import useDocumentTitle from "../useDocumentTitle";

const AccessError = (props) => {
  const titleRef = useRef(null);

  useDocumentTitle(routeInfo.accessDenied.title);
  useEffect(() => {
    if (!window.digitalData) {
      initAnalytics();
    }
    updateAnalyticsData(routeInfo.accessDenied.title, null, {
      eventInfo: {
        eventAction: "error",
        eventType: "technical issue",
        eventCategory: "",
        eventName: "You’ll need to verify",
      },
      attributes: {},
    });
    if (titleRef.current !== null) {
      titleRef.current.focus();
    }
  }, [titleRef]);

  const handleOnClick = (eventName) => {
    updateAnalyticsData(routeInfo.accessDenied.title, null, {
      eventInfo: {
        eventAction: "click",
        eventType: "clickTrack",
        eventCategory: "linkClick",
        eventName: eventName,
      },
      attributes: {},
    });
    sessionStorage.clear();
    localStorage.clear();
  };

  let copyright = "Copyright © 2020 Telstra";
  let privacy_link = "https://www.telstra.com.au/privacy/privacy-statement";
  let termsLink = "https://www.telstra.com.au/terms-of-use";

  return (
    <div id="page-content">
      <header id="header">
        <div className="t-page-header t-able-spacing-5x-mb">
          <div className="t-logo-mobile">
            <img src={"./assets/logo.svg"} alt="" />
            <div className="t-signout able-text-style able-text-style--Base">
              <a onClick={() => sessionStorage.clear()} href={config.logoutUrl}>
                Sign out
              </a>
            </div>
          </div>
        </div>
      </header>
      <main className="t-form-container">
        <Spacing top="spacing5x" bottom="spacing2x">
          <img src={"./assets/error.svg"} alt="" />
        </Spacing>
        <PageTitle
          bottom="spacing2x"
          title={routeInfo.accessDenied.title}
          ref={titleRef}
        />
        <TextStyle alias="Base" element="p" className="px-0">
          To protect your account, this transaction requires that you verify
          your identity.
        </TextStyle>
        <Spacing bottom="spacing7x" bottomVXS="spacing7x"></Spacing>
        <ActionButton
          variant="MediumEmphasis"
          label="Back"
          element="a"
          to={config.logoutUrl}
          onClick={() => handleOnClick("Back")}
          developmentUrl="/able-sprites.svg"
        />
      </main>
      <footer id="footer">
        <div className="t-footer">
          <div className="t-footer-content">
            <p className="t-footer-copyright">{copyright}</p>
            <a
              className="t-footer-privacy"
              href={privacy_link}
              rel="noreferrer"
              target="_blank"
            >
              Privacy
            </a>
            <a
              className="t-footer-terms"
              href={termsLink}
              rel="noreferrer"
              target="_blank"
            >
              Terms of use
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AccessError;
