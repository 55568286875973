import { Actions } from "../constants/managePasskey";

const initialState = {
  isLoading: false,
  createPasskeyOptions: {},
  createPasskeyOptionsFailed: false,
  passkeysList: {},
  isEditPasskeyFailed: false,
  passKeyCreated: false,
  isDeletePasskeyFailed: false,
};

export const ManagePassKey = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_CREATE_PASSKEY_OPTIONS_START: {
      return {
        ...state,
        isLoading: true,
        createPasskeyOptions: {},
        createPasskeyOptionsFailed: false,
      };
    }
    case Actions.FETCH_CREATE_PASSKEY_OPTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        createPasskeyOptions: action.payload,
      };
    }
    case Actions.FETCH_CREATE_PASSKEY_OPTIONS_ERROR: {
      return {
        ...state,
        isLoading: false,
        createPasskeyOptions: {},
        createPasskeyOptionsFailed: true,
      };
    }

    case Actions.FETCH_PASSKEY_LIST_START: {
      return {
        ...state,
        isLoading: true,
        passkeysList: {},
      };
    }
    case Actions.FETCH_PASSKEY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        passkeysList: action.payload,
      };
    }
    case Actions.FETCH_PASSKEY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        passkeysList: {},
      };
    }

    case Actions.POST_EDITED_PASSKEY_DATA_START: {
      return {
        ...state,
        isLoading: true,
        isEditPasskeyFailed: false,
      };
    }
    case Actions.POST_EDITED_PASSKEY_DATA_SUCCESS: {
      const updatedData = { ...state.passkeysList };
      updatedData.creds[action.payload.id].nickname = action.payload.nickname;
      return {
        ...state,
        isLoading: false,
        passkeysList: updatedData,
        isEditPasskeyFailed: false,
      };
    }
    case Actions.POST_EDITED_PASSKEY_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        isEditPasskeyFailed: true,
      };
    }
    case Actions.POST_DELETE_PASSKEY_DATA_START: {
      return {
        ...state,
        isLoading: true,
        isDeletePasskeyFailed: false,
      };
    }
    case Actions.POST_DELETE_PASSKEY_DATA_SUCCESS: {
      const updatedData = { ...state.passkeysList };
      delete updatedData.creds[action.payload.id];
      return {
        ...state,
        isLoading: false,
        passkeysList: updatedData,
        isDeletePasskeyFailed: false,
      };
    }
    case Actions.POST_DELETE_PASSKEY_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        isDeletePasskeyFailed: true,
      };
    }

    case Actions.POST_CREATE_PASSKEY_OPTIONS_START: {
      return {
        ...state,
        isLoading: true,
        createPasskeyOptions: {},
        createPasskeyOptionsFailed: false,
        passKeyCreated: false,
      };
    }
    case Actions.CLEAR_PASSKEY_SUCCESS_MESSAGE: {
      return {
        ...state,
        passKeyCreated: false,
        isDeletePasskeyFailed: false,
        isEditPasskeyFailed: false,
      };
    }
    case Actions.POST_CREATE_PASSKEY_OPTIONS_SUCCESS: {
      const updatedData = { ...state.passkeysList };
      updatedData.creds[action.payload.credentialId] = {
        nickname: action.payload.nickName,
        created: new Date(),
        lastSignIn: "",
      };
      return {
        ...state,
        isLoading: false,
        passKeyCreated: true,
        createPasskeyOptions: {},
        passkeysList: updatedData,
      };
    }
    case Actions.POST_CREATE_PASSKEY_OPTIONS_ERROR: {
      return {
        ...state,
        isLoading: false,
        createPasskeyOptions: {},
        passKeyCreated: false,
      };
    }
    default:
      return state;
  }
};
