import { Actions } from "../constants/manageTdi";

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: {},
  currentUser: {},
  payload: {}
};

export const ChangeUserName = (state = initialState, action) => {
  switch (action.type) {
    case Actions.postChangeUserNameStart: {
      return {
        ...state,
        isLoading: true,
        payload: action.payload
      };
    }
    case Actions.postChangeUserNameSuccess: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: {},
      };
    }
    case Actions.postChangeUserNameError: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    }
    case Actions.setChangeUserNameData: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {},
        currentUser: action.payload,
      };
    }
    default:
      return state;
  }
};
