import { combineReducers } from "redux";
import { ManagePassKey } from "./managePassKey";
import { twoSVDetails } from "./twoSVDetails";
import { TDIUser } from "./manageTdi";
import { ChangeUserName } from "./changeUsername";

// right now we have only 1 reducer, but lets use this format of combineReducers so you can add more later if you need to.
const rootReducer = combineReducers({
  // posts: postsReducer,
  twoSVDetails,
  TDIUser,
  ChangeUserName,
  ManagePassKey,
});

export default rootReducer;
