import ClientStyles from "../clients/ClientStyles";
/* eslint-disable */
/**
 * getEnv
 *
 * Return deployment environment
 *
 */
export const getEnv = (host) => {
  const devEnvs = /sdev9.|sdev18./;
  const uatEnvs = /fn1.|fn2./;
  const fn3 = /fn3./;
  const localEnvs = /localhost|127.0.0.1/;

  if (host.match(devEnvs)) {
    return "devel";
  } else if (host.match(fn3)) {
    return "fn3";
  } else if (host.match(uatEnvs)) {
    return "uat";
  } else if (!host.match(localEnvs)) {
    return "production";
  } else {
    return "local";
  }
};

/* eslint-disable */
export const encodeSessionData = (value) => {
  return value;
  // ? btoa(value) : false;
};

export const decodeSessionData = (value) => {
  return value;
  // ? atob(value) : false;
};

export const getSession = (key) => {
  return sessionStorage && sessionStorage.getItem(key) !== null
    ? JSON.parse(sessionStorage.getItem(key))
    : false;
};
/*export const setSession = (key, value) => {
  const sessionKeyVals = ['urlHash', 'clientCode', 'ClientInfo', 'gotoUrl', 'mfaStatusChange'];
  if(sessionKeyVals.includes(key.trim())) {
    return sessionStorage 
      ? sessionStorage.setItem(key, JSON.stringify(value))
      : false;
  } else {
    return false;
  }
  
};*/
export const clearAllSessions = () => {
  sessionStorage && sessionStorage.clear();
};
export const removeSessionItem = (key) => {
  if (sessionStorage.getItem(key)) sessionStorage.removeItem(key);
};

// Returns a base64url-encoding of an array of bytes
export const a2b = (buf) => {
  return s2b(a2s(buf));
};

// Returns a string given an array of ASCII(?) bytes
export const a2s = (buf) => {
  return String.fromCharCode(...new Uint8Array(buf));
};

// Returns a base64url-encoding of a string
export const s2b = (s) => {
  return btoa(s).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};


// Return a byte array given its base64url-encoding
export const b2a = (s) => {
  return Uint8Array.from(atob(s.replace(/_/g, '/').replace(/-/g, '+')), c => c.codePointAt(0));
}

export const clients = [
  {
    code: "Loyalty",
    theme: "cnsb",
  },
  {
    code: "cnsb",
    theme: "cnsb",
  },
  {
    code: "b2c-identityportal",
    theme: "cnsb",
  },
  {
    code: "mytelstra",
    theme: "cnsb",
  },
  {
    code: "myaccount",
    theme: "cnsb",
  },
  {
    code: "onlineshop_checkout",
    theme: "cnsb",
  },
  {
    code: "telstrasmarthome",
    theme: "cnsb",
  },
  {
    code: "smarthelp",
    theme: "cnsb",
  },
  {
    code: "plus_tickets",
    theme: "cnsb",
  },
  {
    code: "mytelstra-mobile-android",
    theme: "cnsb",
  },
  {
    code: "mytelstra-mobile-ios",
    theme: "cnsb",
  },
  {
    code: "tdev",
    theme: "cnsb",
  },
  {
    code: "telstra-tv-mobile-android",
    theme: "cnsb",
  },
  {
    code: "telstra-tv-mobile-ios",
    theme: "cnsb",
  },
  {
    code: "boxoffice",
    theme: "cnsb",
  },
  {
    code: "boxoffice-roku",
    theme: "cnsb",
  },
  {
    code: "boxoffice-panasonic",
    theme: "cnsb",
  },
  {
    code: "boxoffice-samsung",
    theme: "cnsb",
  },
  {
    code: "boxoffice-lg",
    theme: "cnsb",
  },
  {
    code: "telstra-tv-boxoffice",
    theme: "cnsb",
  },
  {
    code: "telstra-tv-device-reg",
    theme: "cnsb",
  },
  {
    code: "b2c-boost-ppa",
    theme: "boost",
  },
  {
    code: "b2c-boost-ppr",
    theme: "boost",
  },
  {
    code: "b2c-boost-mobile-android",
    theme: "boost",
  },
  {
    code: "b2c-boost-mobile-ios",
    theme: "boost",
  },
  {
    code: "boost-mobile-ios",
    theme: "boost",
  },
  {
    code: "boost-mobile-android",
    theme: "boost",
  },
  {
    code: "boost",
    theme: "boost",
  },
  {
    code: "jbhifi",
    theme: "jbhifi",
  },
  {
    code: "jbhifi-mobile-ios",
    theme: "jbhifi",
  },
  {
    code: "jbhifi-mobile-android",
    theme: "jbhifi",
  },
];

export const getClient = (id) => {
  return clients.filter(({ code }) => code === id);
};

if (!getSession("clientCode")) {
  sessionStorage.setItem("clientCode", JSON.stringify("b2c-identityportal"))
  var clientData = getClient("b2c-identityportal");
  sessionStorage.setItem("ClientInfo", JSON.stringify(clientData[0]))
}

const clientCode = clients.filter(
  ({ code }) => code === getSession("ClientInfo").code
);

export const ThemeConetent = ClientStyles.filter(
  (data) => data.appTheme === clientCode[0].theme
)[0];
