import { Actions } from '../constants/manageTdi';

export const fetchTDIUsers = (payload) => {
    return {
      type: Actions.fetchTDIUsersStart,
      payload
    }
  }
  export const fetchTDIUsersSuccess = (payload) => {
    return {
      type: Actions.fetchTDIUsersSuccess,
      payload
    }
  }
  export const fetchTDIUsersError = (payload) => {
    return {
      type: Actions.fetchTDIUsersError,
      payload
    }
  }
  export const deLinkTDIUsers = (payload) => {
    return {
      type: Actions.deLinkTDIUserStart,
      payload
    }
  }
  export const deLinkTDIUsersSuccess = (payload) => {
    return {
      type: Actions.deLinkTDIUsersSuccess,
      payload
    }
  }
  export const deLinkTDIUsersError = (payload) => {
    return {
      type: Actions.deLinkTDIUserError,
      payload
    }
  }

  export const postChangeUserName = (payload, history) => {
    return {
      type: Actions.postChangeUserName,
      payload,
      history
    }
  }
  export const postChangeUserNameStart = (payload) => {
    return {
      type: Actions.postChangeUserNameStart,
      payload
    }
  }
  export const postChangeUserNameSuccess = (payload) => {
    return {
      type: Actions.postChangeUserNameSuccess,
      payload
    }
  }
  export const postChangeUserNameError = (payload) => {
    return {
      type: Actions.postChangeUserNameError,
      payload
    }
  }
  export const setChangeUserNameData =(payload) => {
    return {
      type: Actions.setChangeUserNameData,
      payload
    }
  }