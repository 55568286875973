import { call, put, takeLatest } from "redux-saga/effects";
import { Actions } from "../constants/manageTdi";
import url from "../helpers/config";
import { getSession } from "../shared/commonFunctions";
import axios from "axios";
import {
  fetchTDIUsersSuccess,
  fetchTDIUsersError,
  deLinkTDIUsersSuccess,
  deLinkTDIUsersError,
  postChangeUserNameStart,
  postChangeUserNameSuccess,
  postChangeUserNameError,
} from "../actions/manageTdiActions";

function* getTDIUsers(action) {
  let { history } = action.payload;
  const accessToken = getSession("AuthData").access_token;
  try {
    const { data } = yield call(axios.get, url.apiBaseUrl + "usernames", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getSession("AuthData").access_token}`,
      },
    });
    if (data.status === 200) {
      yield put(fetchTDIUsersSuccess(data));
    } else {
      yield put(fetchTDIUsersError());
      history.push("/manage-tdi/error");
    }
  } catch (e) {
    yield put(fetchTDIUsersError());
    history.push("/manage-tdi/error");
  }
}

function* deLinkTDIUsers(action) {
  let { history, req } = action.payload;
  const accessToken = getSession("AuthData").access_token;
  try {
    const { data } = yield call(
      axios.post,
      url.apiBaseUrl + "delinkuser",
      req,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getSession("AuthData").access_token}`,
        },
      }
    );
    if (data.status === 200) {
      yield getTDIUsers(action);
      yield put(deLinkTDIUsersSuccess());
    } else {
      yield put(deLinkTDIUsersError());
      history.push("/manage-tdi/error");
    }
  } catch (e) {
    history.push("/manage-tdi/error");
  }
}
function* changeUserName(action) {
  const accessToken = getSession("AuthData").access_token;
  yield put(postChangeUserNameStart(action.payload));
  const gotoUrl = getSession("gotoUrl");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSession("AuthData").access_token}`,
    },
  };
  if (gotoUrl) {
    headers.headers["x-telstra-gotourl"] = gotoUrl;
  }
  try {
    const response = yield call(
      axios.post,
      url.apiBaseUrlV2 + "change-username",
      {
        ...action.payload,
      },
      {
        ...headers,
      }
    );
    if (response.status === 204) {
      yield put(postChangeUserNameSuccess());
    } else {
      yield put(postChangeUserNameError(response));
      action.history.push("/manage-tdi/error");
    }
  } catch (error) {
    let resData =
      error?.response && error?.response?.data ? error.response.data : "";
    if (
      resData &&
      resData.errors?.length &&
      resData.errors[0].code === "4002020"
    ) {
      yield put(
        postChangeUserNameError({
          ...resData.errors[0],
          message:
            "Username is not available, it may already be used on an enterprise account.",
        })
      );
    } else if (
      resData &&
      resData.errors?.length &&
      resData.errors[0].code === "4002021"
    ) {
      yield put(
        postChangeUserNameError({
          ...resData.errors[0],
          message:
            "You can only have one external username linked to your account.",
        })
      );
    } else if (
      resData &&
      resData.errors?.length &&
      resData.errors[0].code === "4002022"
    ) {
      yield put(
        postChangeUserNameError({
          ...resData.errors[0],
          message:
            "Username is not available, it may already be used by another customer.",
        })
      );
    } else {
      yield put(
        postChangeUserNameError(
          error?.response?.data || { message: "Soemthing went Wrong" }
        )
      );

      action.history.push("/manage-tdi/error");
    }
  }
}

export default function* manageTdi() {
  yield takeLatest(Actions.fetchTDIUsersStart, getTDIUsers);
  yield takeLatest(Actions.deLinkTDIUserStart, deLinkTDIUsers);
  yield takeLatest(Actions.postChangeUserName, changeUserName);
}
