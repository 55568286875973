import { lazy } from "react";

const importComponents = [
  {
    path: "/",
    component: lazy(() => import("./Landing")),
  },
  {
    path: "/settings",
    component: lazy(() => import("./Settings/Landing")),
  },
  {
    path: "/manage-tdi",
    component: lazy(() => import("./ManageTDI/ManageTdi")),
  },
  {
    path: "/manage-passkey",
    component: lazy(() => import("./ManagePassKey/index")),
  },
  {
    path: "/manage-tdi/change-username",
    component: lazy(() => import("./ManageTDI/ChangeUserName/ChangeUserName")),
  },
  {
    path: "/manage-tdi/change-username/link-sent",
    component: lazy(() => import("./ManageTDI/ChangeUserName/Success")),
  },
  {
    path: "/manage-tdi/error",
    component: lazy(() => import("./Error/TdiError")),
  },
  {
    path: "/manage-passkey/error",
    component: lazy(() => import("./Error/PasKeyError")),
  },
  {
    path: "/settings/setup-pin",
    component: lazy(() => import("./Settings/SetupPin")),
  },
  {
    path: "/settings/create-pin",
    component: lazy(() => import("./Settings/CreatePin")),
  },
  {
    path: "/settings/pin-success",
    component: lazy(() => import("./Settings/PinSuccess")),
  },
  {
    path: "/settings/optout",
    component: lazy(() => import("./Settings/TurnOffPin")),
  },
  {
    path: "/settings/contacts",
    component: lazy(() => import("./Settings/Contacts")),
  },
  {
    path: "/settings/contact-update",
    component: lazy(() => import("./Settings/ContactUpdate")),
  },
  {
    path: "/settings/verify",
    component: lazy(() => import("./Settings/Verify")),
  },
  {
    path: "/settings/splash",
    component: lazy(() => import("./Settings/VerifySuccess")),
  },
  {
    path: "/devices",
    component: lazy(() => import("./Devices")),
  },
  {
    path: "/lockout",
    component: lazy(() => import("./Lockout")),
  },
  {
    path: "(.*)",
    component: lazy(() => import("./Error")),
  },
];

export default importComponents;
