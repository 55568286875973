export const Actions = {
  FETCH_CREATE_PASSKEY_OPTIONS: "FETCH_CREATE_PASSKEY_OPTIONS",
  FETCH_CREATE_PASSKEY_OPTIONS_START: "FETCH_CREATE_PASSKEY_OPTIONS_START",
  FETCH_CREATE_PASSKEY_OPTIONS_SUCCESS: "FETCH_CREATE_PASSKEY_OPTIONS_SUCCESS",
  FETCH_CREATE_PASSKEY_OPTIONS_ERROR: "FETCH_CREATE_PASSKEY_OPTIONS_ERROR",
  POST_CREATE_PASSKEY_OPTIONS: "POST_CREATE_PASSKEY_OPTIONS",
  POST_CREATE_PASSKEY_OPTIONS_START: "POST_CREATE_PASSKEY_OPTIONS_START",
  POST_CREATE_PASSKEY_OPTIONS_SUCCESS: "POST_CREATE_PASSKEY_OPTIONS_SUCCESS",
  CLEAR_PASSKEY_SUCCESS_MESSAGE: "CLEAR_PASSKEY_SUCCESS_MESSAGE",
  POST_CREATE_PASSKEY_OPTIONS_ERROR: "POST_CREATE_PASSKEY_OPTIONS_ERROR",
  POST_EDITED_PASSKEY_DATA: "POST_EDITED_PASSKEY_DATA",
  POST_EDITED_PASSKEY_DATA_START: "POST_EDITED_PASSKEY_DATA_START",
  POST_EDITED_PASSKEY_DATA_SUCCESS: "POST_EDITED_PASSKEY_DATA_SUCCESS",
  POST_EDITED_PASSKEY_DATA_ERROR: "POST_EDITED_PASSKEY_DATA_ERROR",
  POST_DELETE_PASSKEY_DATA: "POST_DELETE_PASSKEY_DATA",
  POST_DELETE_PASSKEY_DATA_START: "POST_DELETE_PASSKEY_DATA_START",
  POST_DELETE_PASSKEY_DATA_SUCCESS: "POST_DELETE_PASSKEY_DATA_SUCCESS",
  POST_DELETE_PASSKEY_DATA_ERROR: "POST_DELETE_PASSKEY_DATA_ERROR",
  FETCH_PASSKEY_LIST: "FETCH_PASSKEY_LIST",
  FETCH_PASSKEY_LIST_START: "FETCH_PASSKEY_LIST_START",
  FETCH_PASSKEY_LIST_SUCCESS: "FETCH_PASSKEY_LIST_SUCCESS",
  FETCH_PASSKEY_LIST_ERROR: "FETCH_PASSKEY_LIST_ERROR",
};
